import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

import 'bootstrap/dist/css/bootstrap.css';

import API from 'src/apiRequest';
import OrderIcon from 'src/components/OrderIcon/OrderIcon';
import UserContext from 'src/contexts/UserContext';
import Loading from 'src/Loading';
import FormError from 'src/orderForm/FormError';
import NoAvailablePackages from 'src/orderForm/NoAvailablePackages';
import OrderForm from 'src/orderForm/OrderForm/OrderForm';
import OrderFormPageFooter from 'src/orderForm/OrderForm/OrderFormPage/OrderFormPageFooter';
import OrderFormPageHeader from 'src/orderForm/OrderForm/OrderFormPage/OrderFormPageHeader';
import PackageConsumption from 'src/orderForm/PackageConsumption';
import uiSchemaDisabledEdit from 'src/orderForm/uiSchema/uiSchemaDisabled.json';
import uiSchemaFullEdit from 'src/orderForm/uiSchema/uiSchemaFullEdit.json';
import uiSchemaImobiliariaDigital from 'src/orderForm/uiSchema/uiSchemaImobiliariaDigital.json';
import uiSchemaImobiliariaDigitalNonPriceable from 'src/orderForm/uiSchema/uiSchemaImobiliariaDigitalNonPriceable.json';
import uiSchemaPartialEdit from 'src/orderForm/uiSchema/uiSchemaNonPriceableEdit.json';
import AddressService from 'src/services/addressService';
import { syncTags } from 'src/store/ducks/tags';

const styles = {
  container: {
    fontWeight: 'bold',
    alignItems: 'center',
  },
  paper: {
    marginLeft: '1vw',
    marginRight: '1vw',
    paddingBottom: '0.5vw',
    paddingTop: '0.5vw',
    fontSize: 'calc(8px + 0.4vw)',
  },
  form: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: 'calc(6px + 0.3vw)',
  },
};

class EditOrderFormPage extends Component {
  static contextType = UserContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      submitted: false,
      selectedPackage: '',
      newOrderStatus: 'SAVE_ORDER', // 'SAVE_ORDER' -> 'PURCHASE_PACKAGE' -> 'CONFIRM_ORDER'
      errors: 0,
      loading: true,
      schema: {},
      uiSchema: uiSchemaPartialEdit,
      uiSchemaDisabled: uiSchemaDisabledEdit,
      formData: {},
      packageConsume: {},
      orderStatus: '',
      orderType: '',
      apiError: false,
    };
    this.form = null;
    this.addressService = new AddressService(context?.profile?.country);
  }

  async componentDidMount() {
    const { orderId = null } = this.props.match.params;
    const { cancelEditOrder, clientPanel, intl } = this.props;
    const me = this.context;

    try {
      const { data } = await API.get(`/orders/${orderId}`);

      if (data?.editable) {
        const generatedUiSchema = this.generateUiSchema(
          data?.status,
          data?.schema?.definitions?.parties_involved,
          data?.data?.inspection_type
        );

        const generatedUiSchemaDisabled = this.generateUiSchema(
          undefined,
          data?.schema?.definitions?.parties_involved,
          data?.data?.inspection_type
        );

        if (clientPanel) {
          generatedUiSchema.additionalCosts = {
            'ui:order': ['price', 'description'],
            price: {
              ...generatedUiSchema.additionalCosts?.price,
              'ui:disabled': true,
            },
            description: {
              ...generatedUiSchema.additionalCosts?.description,
              'ui:disabled': true,
            },
          };

          generatedUiSchema.clients = {
            'ui:widget': 'hidden',
            client: {
              'ui:widget': 'hidden',
            },
          };

          generatedUiSchema.franchisees = {
            'ui:widget': 'hidden',
            franchisee: {
              'ui:widget': 'hidden',
            },
          };
        } else {
          generatedUiSchema.franchisees = {
            'ui:widget': 'hidden',
            franchisee: {
              'ui:widget': 'hidden',
            },
          };

          this.orderAddressUiSchema({ addressUiSchema: generatedUiSchema.address });
        }

        if (data?.data?.accessInformation?.address) {
          this.orderAddressUiSchema({ addressUiSchema: generatedUiSchema.accessInformation.address });
        } else if (data?.data?.propertyAccess?.address) {
          this.orderAddressUiSchema({ addressUiSchema: generatedUiSchema.propertyAccess.address });
        }

        if (data?.data?.accessInformation?.keysDevolutionAddress) {
          this.orderAddressUiSchema({ addressUiSchema: generatedUiSchema.accessInformation.keysDevolutionAddress });
        } else if (data?.data?.propertyAccess?.keysDevolutionAddress) {
          this.orderAddressUiSchema({ addressUiSchema: generatedUiSchema.propertyAccess.keysDevolutionAddress });
        }

        if ((me.profile?.franchisee_type === 'OWNED' || [244, 187].includes(me.profile?.franchisee_id)) && generatedUiSchema?.additionals?.urgency) {
          generatedUiSchema.additionals.urgency = { 'ui:widget': 'hidden' };
        }

        this.setState({
          schema: data?.schema,
          uiSchema: generatedUiSchema,
          uiSchemaDisabled: generatedUiSchemaDisabled,
          loading: false,
          formData: data?.data,
          orderStatus: data?.status,
          orderType: data?.type,
        });
      } else {
        alert(intl.formatMessage({ id: 'MESSAGE.OFFICIAL_REQUIRED' }));

        cancelEditOrder();
      }
    } catch (err) {
      console.debug('[DEBUG] EditOrderFormPage componentDidMount error ', err);
    }
  }

  orderAddressUiSchema = ({ addressUiSchema }) => {
    addressUiSchema['ui:order'] = ['zipcode', 'state', 'city', 'neighborhood', 'street', 'number', 'complement'];
  };

  /**
   * @description Function to handle the submit of the form
   */
  handleSubmit = () => {
    this.setState({ submitted: true });
    this.form.submitButton.click();
  };

  /**
   * @description Function to handle the confirmatio of the order, save the new order
   */
  handleConfirm = () => this.form.submitButton.click();

  /**
   * @description Function to handle the selection of a package
   */
  handleSelectPackage = event => this.setState({ selectedPackage: event.target.value });

  /**
   * @description Function to handle the purchase of a package
   */
  handlePurchase = () => {
    const { formData, selectedPackage } = this.state;

    if (selectedPackage !== '') {
      API.post(`/packages/${selectedPackage}/clients`, {
        client_id: formData.clients.client,
      })
        .then(() => {
          this.setState({ newOrderStatus: 'SAVE_ORDER' }, () => this.form.submitButton.click());
        })
        .catch(err => {
          console.debug('[DEBUG] EditOrderFormPage handlePurchase error ', err);
        });
    } else {
      this.form.submitButton.click();
    }
  };

  /**
   * @description Function to handle errors of the form
   */
  handleError = errorsNumber => this.setState({ errors: errorsNumber });

  /**
   * @description Function to handle the cancel button onClick
   */
  handleCancel = () => {
    const { cancelEditOrder, intl } = this.props;
    const { newOrderStatus } = this.state;

    const confMessage = intl.formatMessage({ id: 'confirmation.cancelEditOrder' });

    switch (newOrderStatus) {
      case 'SAVE_ORDER':
        if (window.confirm(confMessage)) cancelEditOrder();

        break;

      default:
        this.setState({ newOrderStatus: 'SAVE_ORDER' });
    }
  };

  /**
   * @description Function to handle the loading
   */
  handleLoading = () => this.setState(state => ({ loading: !state.loading }));

  /**
   * @description Function called to submit the form after the form validation
   */
  onSubmit = ({ formData }) => {
    const { newOrderStatus } = this.state;

    if (formData.recommendation) delete formData.recommendation;

    switch (newOrderStatus.newOrderStatus) {
      case 'SAVE_ORDER':
        API.post('/financial/packageconsume', JSON.stringify(formData))
          .then(res => {
            if (res.data.priceHasChanged === false) {
              this.setState({ newOrderStatus: 'SUBMIT' });

              this.handleSubmit();
            } else if (res.data.hasRequiredCredits) {
              this.setState({
                newOrderStatus: 'CONFIRM_ORDER',
                packageConsume: res.data,
              });
            } else {
              this.setState({
                newOrderStatus: 'PURCHASE_PACKAGE',
                packageConsume: res.data,
                formData,
              });
            }
          })
          .catch(err => {
            console.debug('[DEBUG] EditOrderFormPage onSubmit error ', err);
          });

        break;

      default: {
        const { orderId } = this.props.match.params;
        const { successfulOrderEdition, updateOrderItem } = this.props;

        if (formData.type === 'docusign' || formData.type === 'fichacerta') formData.action = 'correct';

        API.put(`/orders/${orderId}`, JSON.stringify(formData))
          .then(res => {
            const data = { ...res.data };

            if (updateOrderItem) updateOrderItem(data);

            this.props.syncTags();
            successfulOrderEdition(orderId);
          })
          .catch(err => {
            // eslint-disable-next-line quotes
            if (err.response.data.error === "'edit' action is not allowed with the current context.") {
              this.setState({ apiError: 'INSPECTION_NOT_COPIED_ERROR' });
            } else {
              this.setState({ apiError: 'UNKNOWN_GENERIC_ERROR' });
            }

            console.debug('[DEBUG] EditOrderFormPage onSubmit error ', err);
          });

        break;
      }
    }
  };

  /**
   * @description Generate the UI schema based on the order to edit status
   */
  generateUiSchema = (orderStatus, partiesInvolved, inspectionType) => {
    const { clientPanel } = this.props;

    let uiSchema;
    let disabled = false;

    if (clientPanel === true) {
      switch (orderStatus) {
        case 'WAITING':
          uiSchema = uiSchemaFullEdit;
          break;

        case 'FINISHED':
          uiSchema = uiSchemaPartialEdit;
          break;

        default:
          uiSchema = uiSchemaDisabledEdit;
          disabled = true;
      }
    } else {
      switch (orderStatus) {
        case 'ACCEPTED':
        case 'FINALIZED_BY_DEVICE':
          uiSchema = uiSchemaFullEdit;
          break;

        case 'FINISHED':
          uiSchema = uiSchemaPartialEdit;
          break;

        default:
          uiSchema = uiSchemaDisabledEdit;
          disabled = true;
      }
    }

    if (inspectionType === 'visitaDigital' || inspectionType === 'fotosDeCaptacao') {
      if (clientPanel === true || orderStatus === 'FINISHED') {
        uiSchema = { ...uiSchema, ...uiSchemaImobiliariaDigitalNonPriceable };
      } else {
        uiSchema = { ...uiSchema, ...uiSchemaImobiliariaDigital };
      }
    }

    if (inspectionType === 'tourVirtual') {
      uiSchema = {
        ...uiSchema,
        'ui:order': [
          'inspection_type',
          'franchisees',
          'clients',
          'details',
          'address',
          'accessInformation',
          'accompaniedInspection',
          'tourAccessInfo',
          '*',
          'recommendation',
        ],
      };
    }

    if (partiesInvolved) {
      const partiesInvolvedUI = this.generatePartiesInvolvedUi(partiesInvolved, disabled);

      return update(uiSchema, { parties_involved: { $merge: partiesInvolvedUI } });
    }

    return uiSchema;
  };

  /**
   * @description Generate the parties involved UI schema section
   */
  generatePartiesInvolvedUi = (partiesInvolved, disabled) => {
    const partiesInvolvedUI = {};

    Object.keys(partiesInvolved?.properties).forEach(key => {
      if (partiesInvolved.properties[key].$ref.search('people') === -1) {
        partiesInvolvedUI[key] = {
          'ui:field': 'person',
          'ui:disabled': disabled,
        };
      } else {
        partiesInvolvedUI[key] = {
          items: {
            'ui:field': 'people',
            'ui:title': partiesInvolved.properties[key].title,
            'ui:disabled': disabled,
          },
        };
      }
    });

    return partiesInvolvedUI;
  };

  render() {
    const {
      errors,
      formData,
      loading,
      newOrderStatus,
      orderType,
      packageConsume,
      selectedPackage,
      schema,
      submitted,
      uiSchema,
      uiSchemaDisabled,
      apiError,
    } = this.state;
    const { clientPanel, inspectionIcon, updateOrderItem } = this.props;
    let handleSecondButton;
    let textSecondButton;
    let footerContent;

    switch (newOrderStatus) {
      case 'PURCHASE_PACKAGE':
        handleSecondButton = this.handlePurchase;
        textSecondButton = 'BUTTON.PURCHASE';
        footerContent = (
          <NoAvailablePackages
            data={packageConsume}
            handleSelectPackage={this.handleSelectPackage}
            selectedPackage={selectedPackage}
          />
        );
        break;

      case 'CONFIRM_ORDER':
        handleSecondButton = this.handleConfirm;
        textSecondButton = 'BUTTON.CONFIRM';
        footerContent = <PackageConsumption data={packageConsume} />;
        break;

      default:
        handleSecondButton = this.handleSubmit;
        textSecondButton = 'BUTTON.SAVE';
        if (errors > 0) {
          footerContent = (
            <FormError>
              <FormattedMessage id="orderForm.footerError" values={{ errors }} />
            </FormError>
          );
        }
    }

    if (apiError) {
      footerContent = (
        <FormError>
          <FormattedMessage id={apiError} />
        </FormError>
      );
    }

    if (loading) return <Loading clientPanel={clientPanel} />;

    return (
      <Paper style={styles.paper} elevation={0}>
        <Grid container>
          <OrderFormPageHeader
            inspectionIcon={inspectionIcon || <OrderIcon orderType={orderType} fontSize="calc(16px + 0.8vw)" />}
            text="orderForm.editOrder"
          />

          <Grid container>
            <Grid item xs={12} style={styles.form}>
              <OrderForm
                ref={form => {
                  this.form = form;
                }}
                submitted={submitted}
                updateOrderItem={updateOrderItem}
                handleError={this.handleError}
                formData={formData}
                handleLoading={this.handleLoading}
                schema={schema}
                uiSchema={newOrderStatus === 'SAVE_ORDER' ? uiSchema : uiSchemaDisabled}
                onSubmit={this.onSubmit}
                orderType={orderType}
              />
            </Grid>
          </Grid>

          <OrderFormPageFooter
            handleFirstButton={this.handleCancel}
            handleSecondButton={handleSecondButton}
            textFirstButton="BUTTON.CANCEL"
            textSecondButton={textSecondButton}
          >
            {footerContent}
          </OrderFormPageFooter>
        </Grid>
      </Paper>
    );
  }
}

EditOrderFormPage.propTypes = {
  cancelEditOrder: PropTypes.func.isRequired,
  clientPanel: PropTypes.bool.isRequired,
  inspectionIcon: PropTypes.object,
  intl: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  orderStatus: PropTypes.string,
  successfulOrderEdition: PropTypes.func.isRequired,
  syncTags: PropTypes.func.isRequired,
  updateOrderItem: PropTypes.func,
};

EditOrderFormPage.defaultProps = {
  inspectionIcon: null,
  orderStatus: '',
  updateOrderItem: undefined,
};

export default withStyles(styles)(connect(null, { syncTags })(injectIntl(withRouter(EditOrderFormPage))));
