import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

import 'bootstrap/dist/css/bootstrap.css';

import API from 'src/apiRequest';
import ClientSteps from 'src/components/ClientSteps/ClientSteps';
import PlanSwitch from 'src/components/common/PlanSwitch';
import ApiContext from 'src/contexts/ApiContext';
import UserContext from 'src/contexts/UserContext';
import delay from 'src/helpers/delay';
import removeDuplicates from 'src/helpers/removeDuplicates';
import Loading from 'src/Loading';
import ClientOrderForm from 'src/orderForm/ClientOrderForm/ClientOrderForm';
import classes from 'src/orderForm/ClientOrderForm/ClientOrderFormPage/ClientOrderFormPage.module.css';
import FormError from 'src/orderForm/FormError';
import GenericWarning from 'src/orderForm/GenericWarning';
import NoAvailablePackages from 'src/orderForm/NoAvailablePackages';
import makeWidgets from 'src/orderForm/OrderForm/makeWidgets';
import OrderFormPageFooter from 'src/orderForm/OrderForm/OrderFormPage/OrderFormPageFooter';
import PackageConsumption from 'src/orderForm/PackageConsumption';
import uiSchemaImobiliariaDigital from 'src/orderForm/uiSchema/uiSchemaImobiliariaDigitalNonPriceable.json';
import uiSchema from 'src/orderForm/uiSchema/uiSchemaNew.json';
import UnproductiveWarning from 'src/orderForm/UnproductiveWarning';
import AddressService from 'src/services/addressService';
import { syncTags } from 'src/store/ducks/tags';

class ClientOrderFormPage extends Component {
  static contextType = UserContext;

  orderTypesWithNoNestedSchema = ['marketplace_docusign', 'marketplace_fichacerta', 'visitaDigital', 'fotosDeCaptacao'];

  constructor(props, context) {
    super(props, context);

    this.state = {
      submitted: false,
      errors: 0,
      loading: true,
      schema: {},
      stepsSchema: {},
      uiSchema,
      step: 0,
      stepsFormData: {},
      newOrderStatus: 'FORM_STEP', // 'FORM_STEP' -> 'PURCHASE_PACKAGE' -> 'CONFIRM_ORDER'
      selectedPackage: '',
      formUsesRootSchemaAsMainSchema: false,
      apiStatus: 'IDLE',

      addressesStates: [],

      addressCities: [],
      addressNeighborhoods: [],
      addressSelectedState: null,
      addressSelectedCity: null,
      addressSelectedNeighborhood: null,

      accessInformationAddressCities: [],
      accessInformationAddressNeighborhoods: [],
      accessInformationAddressSelectedState: null,
      accessInformationAddressSelectedCity: null,
      accessInformationAddressSelectedNeighborhood: null,

      propertyAccessAddressCities: [],
      propertyAccessAddressNeighborhoods: [],
      propertyAccessAddressSelectedState: null,
      propertyAccessAddressSelectedCity: null,
      propertyAccessAddressSelectedNeighborhood: null,

      accessInformationKeysDevolutionAddressCities: [],
      accessInformationKeysDevolutionAddressNeighborhoods: [],
      accessInformationKeysDevolutionAddressSelectedState: null,
      accessInformationKeysDevolutionAddressSelectedCity: null,
      accessInformationKeysDevolutionAddressSelectedNeighborhood: null,

      propertyAccessKeysDevolutionAddressCities: [],
      propertyAccessKeysDevolutionAddressNeighborhoods: [],
      propertyAccessKeysDevolutionAddressSelectedState: null,
      propertyAccessKeysDevolutionAddressSelectedCity: null,
      propertyAccessKeysDevolutionAddressSelectedNeighborhood: null,

      desiredPropertyAddressCities: [],
      desiredPropertyAddressNeighborhoods: [],
      desiredPropertyAddressSelectedState: null,
      desiredPropertyAddressSelectedCity: null,
      desiredPropertyAddressSelectedNeighborhood: null,

      mainSuitorAddressCities: [],
      mainSuitorAddressNeighborhoods: [],
      mainSuitorAddressSelectedState: null,
      mainSuitorAddressSelectedCity: null,
      mainSuitorAddressSelectedNeighborhood: null,

      realEstateAddresses: [],

      changeAccessInformationAddressFieldsAutomatically: false,
      changePropertyAccessAddressFieldsAutomatically: false,
      changeAccessInformationkeysDevolutionAddressFieldsAutomatically: false,
      changePropertyAccesskeysDevolutionAddressFieldsAutomatically: false,
    };
    this.accessToken = null;
    this.tokenType = null;
    this.features = [];
    this.plan = null;
    this.steps = [];
    this.addressService = new AddressService(context?.profile?.country);
    this.hasBudgetDuringInspectionSectionAllowed = false;
  }

  presetAddressStatesOptionsOnSchema = (initialState, addressStates = []) => {
    if (initialState.schema?.definitions?.address) {
      initialState.schema = update(initialState.schema, {
        definitions: {
          address: {
            properties: {
              state: {
                enum: { $set: addressStates.map(option => option?.name) || [] },
                enumNames: { $set: addressStates.map(option => option?.name) || [] },
              },
            },
          },
        },
      });
    }

    if (initialState.stepsSchema?.address) {
      initialState.stepsSchema = update(initialState.stepsSchema, {
        address: {
          properties: {
            state: {
              enum: { $set: addressStates.map(option => option?.name) || [] },
              enumNames: { $set: addressStates.map(option => option?.name) || [] },
            },
          },
        },
      });
    }

    if (initialState.schema?.definitions?.desired_property_address) {
      initialState.schema = update(initialState.schema, {
        definitions: {
          desired_property_address: {
            properties: {
              state: {
                enum: { $set: addressStates.map(option => option?.name) || [] },
                enumNames: { $set: addressStates.map(option => option?.name) || [] },
              },
            },
          },
        },
      });
    }

    if (initialState.schema?.definitions?.main_suitor_address) {
      initialState.schema = update(initialState.schema, {
        definitions: {
          main_suitor_address: {
            properties: {
              state: {
                enum: { $set: addressStates.map(option => option?.name) || [] },
                enumNames: { $set: addressStates.map(option => option?.name) || [] },
              },
            },
          },
        },
      });
    }
  };

  presetAddressesFieldsValuesOnFormData = initialState => {
    if (initialState.stepsFormData?.address?.state) {
      initialState.addressSelectedState = {
        label: initialState.stepsFormData.address.state,
        value: initialState.stepsFormData.address.state,
      };
    }

    if (initialState.stepsFormData?.address?.city) {
      initialState.addressSelectedCity = {
        label: initialState.stepsFormData.address.city,
        value: initialState.stepsFormData.address.city,
      };
    }

    if (initialState.stepsFormData?.address?.neighborhood) {
      initialState.addressSelectedNeighborhood = {
        label: initialState.stepsFormData.address.neighborhood,
        value: initialState.stepsFormData.address.neighborhood,
      };
    }

    if (initialState.stepsFormData?.accessInformation?.address?.state) {
      initialState.accessInformationAddressSelectedState = {
        label: initialState.stepsFormData.accessInformation.address.state,
        value: initialState.stepsFormData.accessInformation.address.state,
      };
    }

    if (initialState.stepsFormData?.accessInformation?.address?.city) {
      initialState.accessInformationAddressSelectedCity = {
        label: initialState.stepsFormData.accessInformation.address.city,
        value: initialState.stepsFormData.accessInformation.address.city,
      };
    }

    if (initialState.stepsFormData?.accessInformation?.address?.neighborhood) {
      initialState.accessInformationAddressSelectedNeighborhood = {
        label: initialState.stepsFormData.accessInformation.address.neighborhood,
        value: initialState.stepsFormData.accessInformation.address.neighborhood,
      };
    }

    if (initialState.stepsFormData?.accessInformation?.keysDevolutionAddress?.state) {
      initialState.accessInformationKeysDevolutionAddressSelectedState = {
        label: initialState.stepsFormData.accessInformation.keysDevolutionAddress.state,
        value: initialState.stepsFormData.accessInformation.keysDevolutionAddress.state,
      };
    }

    if (initialState.stepsFormData?.accessInformation?.keysDevolutionAddress?.city) {
      initialState.accessInformationKeysDevolutionAddressSelectedCity = {
        label: initialState.stepsFormData.accessInformation.keysDevolutionAddress.city,
        value: initialState.stepsFormData.accessInformation.keysDevolutionAddress.city,
      };
    }

    if (initialState.stepsFormData?.accessInformation?.keysDevolutionAddress?.neighborhood) {
      initialState.accessInformationKeysDevolutionAddressSelectedNeighborhood = {
        label: initialState.stepsFormData.accessInformation.keysDevolutionAddress.neighborhood,
        value: initialState.stepsFormData.accessInformation.keysDevolutionAddress.neighborhood,
      };
    }

    if (initialState.stepsFormData?.propertyAccess?.address?.state) {
      initialState.propertyAccessAddressSelectedState = {
        label: initialState.stepsFormData.propertyAccess.address.state,
        value: initialState.stepsFormData.propertyAccess.address.state,
      };
    }

    if (initialState.stepsFormData?.propertyAccess?.address?.city) {
      initialState.propertyAccessAddressSelectedCity = {
        label: initialState.stepsFormData.propertyAccess.address.city,
        value: initialState.stepsFormData.propertyAccess.address.city,
      };
    }

    if (initialState.stepsFormData?.propertyAccess?.address?.neighborhood) {
      initialState.propertyAccessAddressSelectedNeighborhood = {
        label: initialState.stepsFormData.propertyAccess.address.neighborhood,
        value: initialState.stepsFormData.propertyAccess.address.neighborhood,
      };
    }

    if (initialState.stepsFormData?.propertyAccess?.keysDevolutionAddress?.state) {
      initialState.propertyAccessKeysDevolutionAddressSelectedState = {
        label: initialState.stepsFormData.propertyAccess.keysDevolutionAddress.state,
        value: initialState.stepsFormData.propertyAccess.keysDevolutionAddress.state,
      };
    }

    if (initialState.stepsFormData?.propertyAccess?.keysDevolutionAddress?.city) {
      initialState.propertyAccessKeysDevolutionAddressSelectedCity = {
        label: initialState.stepsFormData.propertyAccess.keysDevolutionAddress.city,
        value: initialState.stepsFormData.propertyAccess.keysDevolutionAddress.city,
      };
    }

    if (initialState.stepsFormData?.propertyAccess?.keysDevolutionAddress?.neighborhood) {
      initialState.propertyAccessKeysDevolutionAddressSelectedNeighborhood = {
        label: initialState.stepsFormData.propertyAccess.keysDevolutionAddress.neighborhood,
        value: initialState.stepsFormData.propertyAccess.keysDevolutionAddress.neighborhood,
      };
    }
  };

  async componentDidMount() {
    const { uiSchema: uiSchemaState } = this.state;
    const { newOrderType, previousOrderFile, previousOrderId, previousPropertyId } = this.props;
    const me = this.context;

    this.setState({ loading: true });

    this.accessToken = sessionStorage.getItem('accessToken');
    this.tokenType = sessionStorage.getItem('tokenType');
    this.features = me?.features;
    this.plan = me?.plan || null;
    this.hasBudgetDuringInspectionSectionAllowed =
      me?.features?.includes('FORM_BUDGET_DURING_INSPECTION') && newOrderType === 'saida';

    const params = new URLSearchParams();

    params.append('type', newOrderType);

    if (previousOrderId !== null) params.append('previous_id', previousOrderId);

    if (previousPropertyId !== null) params.append('property_id', previousPropertyId);

    try {
      const { data } = await API.get('/orders/new', { params });

      const stepsSchema = this.convertPropertiesToDefinitions(data?.schema?.properties, data?.schema?.definitions);
      const formData = { ...data?.data };
      let newUiSchema = { ...uiSchemaState };

      if (
        !this.hasBudgetDuringInspectionSectionAllowed &&
        stepsSchema?.budgetDuringInspection &&
        data?.schema?.definitions?.budgetDuringInspection &&
        data?.schema?.properties?.budgetDuringInspection
      ) {
        delete stepsSchema.budgetDuringInspection;
        delete data.schema.definitions.budgetDuringInspection;
        delete data.schema.properties.budgetDuringInspection;
      }

      if (stepsSchema?.parties_involved) {
        stepsSchema.parties_involved.definitions = Object.keys(data.schema.definitions)
          .filter(def => def === 'people' || def === 'person')
          .reduce((obj, key) => {
            obj[key] = data.schema.definitions[key];
            return obj;
          }, {});

        stepsSchema.parties_involved.title = 'parties_involved';

        Object.keys(stepsSchema.parties_involved?.properties).forEach(key => {
          if (stepsSchema.parties_involved.properties[key].$ref.search('people') === -1) {
            newUiSchema.parties_involved[key] = { 'ui:field': 'person' };
          } else {
            newUiSchema.parties_involved[key] = {
              items: {
                'ui:field': 'people',
                'ui:title': stepsSchema.parties_involved.properties[key].title,
              },
            };
          }
        });
      }

      if (stepsSchema?.reportSubmission) {
        stepsSchema.reportSubmission.definitions = Object.keys(data.schema.definitions)
          .filter(def => def === 'recipients' || def === 'recipient')
          .reduce((obj, key) => {
            obj[key] = data.schema.definitions[key];
            return obj;
          }, {});
      }

      if (stepsSchema?.accessInformation) {
        stepsSchema.accessInformation.definitions = Object.keys(data.schema.definitions)
          .filter(def => def === 'address')
          .reduce((obj, key) => {
            obj[key] = data.schema.definitions[key];
            return obj;
          }, {});
      }

      if (stepsSchema?.propertyAccess) {
        stepsSchema.propertyAccess.definitions = Object.keys(data.schema.definitions)
          .filter(def => def === 'address')
          .reduce((obj, key) => {
            obj[key] = data.schema.definitions[key];
            return obj;
          }, {});
      }

      if (stepsSchema?.attachments) {
        if (!formData.attachments) formData.attachments = [];
        stepsSchema.attachments = {
          ...stepsSchema.attachments,
          title: 'attachments',
          definitions: Object.keys(data.schema.definitions)
            .filter(def => def === 'file')
            .reduce((obj, key) => {
              obj[key] = data.schema.definitions[key];
              return obj;
            }, {}),
        };
      }

      if (newOrderType.includes('marketplace_')) {
        this.setState({ formUsesRootSchemaAsMainSchema: true, newOrderStatus: 'MARKETPLACE' });
      }

      if (newOrderType === 'visitaDigital' || newOrderType === 'fotosDeCaptacao') {
        newUiSchema = { ...newUiSchema, ...uiSchemaImobiliariaDigital };

        if (stepsSchema.clients) {
          newUiSchema.clients = { 'ui:widget': 'hidden', client: { 'ui:widget': 'hidden' } };
        }

        if (stepsSchema.franchisees) {
          newUiSchema.franchisees = { 'ui:widget': 'hidden', franchisee: { 'ui:widget': 'hidden' } };

          formData.franchisees = {
            franchisee: stepsSchema.franchisees.properties.franchisee.enum[0],
          };

          stepsSchema.clients.required = [];
        }

        this.setState({ formUsesRootSchemaAsMainSchema: true, newOrderStatus: 'IMOBILIARIA_DIGITAL' });
      }

      if (stepsSchema.inspection_type) formData.inspection_type = stepsSchema.inspection_type.default;

      if (previousOrderId && Object.keys(data.data).length > 0 && !previousPropertyId) {
        const inpection = {
          inspection: {
            id: previousOrderId,
            name: data.data.details.building_id,
          },
        };

        formData.previous_inspection = inpection;
      } else {
        if (previousOrderFile) formData.previous_inspection = previousOrderFile;

        if (stepsSchema.additionals) {
          const additionals = {
            modality: stepsSchema.additionals.properties.modality.default,
            urgency: stepsSchema.additionals.properties.urgency.default,
          };

          formData.additionals = additionals;
        }
      }

      const steps = [
        'franchisees',
        'details',
        'address',
        'parties_involved',
        'keys',
        'additionals',
        'accessInformation',
        'payment',
      ];

      if (['entrada', 'saida', 'aditivo', 'conferencia'].includes(newOrderType)) {
        steps.splice(steps.indexOf('franchisees'), 1);
      } else if (!stepsSchema.franchisees || stepsSchema.franchisees.properties.franchisee.enum.length <= 1) {
        steps.splice(0, 1);

        if (stepsSchema.franchisees) {
          formData.franchisees = {
            franchisee: stepsSchema.franchisees.properties.franchisee.enum[0],
          };
        }
      }

      if (this.features.indexOf('FORM_ACCESSINFO') < 0) {
        steps.splice(steps.indexOf('accessInformation'), 1);
      }

      if (newOrderType === 'tourVirtual') {
        steps.splice(steps.indexOf('parties_involved'), 1);
        steps.splice(steps.indexOf('keys'), 1);
        steps.splice(steps.indexOf('additionals'), 1);
      }

      if (newOrderType === 'acompanhamentoObraMM') {
        if (steps.indexOf('additionals')) steps.splice(steps.indexOf('additionals'), 1);
        if (steps.indexOf('accessInformation') > -1) steps.splice(steps.indexOf('accessInformation'), 1);

        steps.splice(steps.indexOf('address'), 0, 'construction_details');
        steps.splice(steps.indexOf('address'), 0, 'solicitations');
      }

      if (
        (me.profile?.franchisee_type === 'OWNED' || [244, 187].includes(me.profile?.franchisee_id)) &&
        newUiSchema?.additionals?.urgency
      ) {
        newUiSchema.additionals.urgency = { 'ui:widget': 'hidden' };
      }

      const fetchedStates = this.addressService.getStates();

      const initialState = {
        stepsSchema,
        schema: data.schema,
        uiSchema: newUiSchema,
        stepsFormData: formData,
        addressesStates: fetchedStates,
      };

      this.presetAddressStatesOptionsOnSchema(initialState, fetchedStates);
      this.presetAddressesFieldsValuesOnFormData(initialState);

      this.steps = steps;
      this.setState(initialState);
    } catch (err) {
      console.debug('[DEBUG] componentDidMount error ', err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(_, prevState) {
    const { realEstateAddresses } = this.state;

    const updateAddressFields = async ({ prop, addressType, type, addressKey, realEstateKey }) => {
      if (this.state?.[prop] && prevState[prop] !== this.state[prop] && realEstateAddresses.length) {
        const { address: uniqueAddress, public_id: realEstateId } = realEstateAddresses[0];

        const addressStatePrefix = addressType + addressKey.charAt(0).toUpperCase() + addressKey.slice(1);

        const updatedAddress = {
          zipcode: uniqueAddress.zipcode,
          street: uniqueAddress.street,
          neighborhood: uniqueAddress.neighborhood,
          city: uniqueAddress.city,
          state: uniqueAddress.state,
          complement: uniqueAddress.complement || '',
          number: uniqueAddress.number || '',
        };

        await delay(100);

        this.setState(state => ({
          [`${addressStatePrefix}SelectedState`]: {
            label: updatedAddress.state || null,
            value: updatedAddress.state || null,
          },
          [`${addressStatePrefix}SelectedCity`]: {
            label: updatedAddress.city || null,
            value: updatedAddress.city || null,
          },
          [`${addressStatePrefix}SelectedNeighborhood`]: {
            label: updatedAddress.neighborhood || null,
            value: updatedAddress.neighborhood || null,
          },

          stepsFormData: {
            ...state.stepsFormData,
            [addressType]: {
              ...state.stepsFormData[addressType],
              [type]: 'Na imobiliária',
              [addressKey]: updatedAddress,
              [realEstateKey]: realEstateId,
            },
          },
        }));
      }
    };

    await updateAddressFields({
      prop: 'changeAccessInformationAddressFieldsAutomatically',
      addressType: 'accessInformation',
      type: 'type',
      addressKey: 'address',
      realEstateKey: 'realEstateAddress',
    });
    await updateAddressFields({
      prop: 'changePropertyAccessAddressFieldsAutomatically',
      addressType: 'propertyAccess',
      type: 'type',
      addressKey: 'address',
      realEstateKey: 'realEstateAddress',
    });
    await updateAddressFields({
      prop: 'changeAccessInformationkeysDevolutionAddressFieldsAutomatically',
      addressType: 'accessInformation',
      type: 'keysDevolutionType',
      addressKey: 'keysDevolutionAddress',
      realEstateKey: 'devolutionRealEstateAddress',
    });
    await updateAddressFields({
      prop: 'changePropertyAccesskeysDevolutionAddressFieldsAutomatically',
      addressType: 'propertyAccess',
      type: 'keysDevolutionType',
      addressKey: 'keysDevolutionAddress',
      realEstateKey: 'devolutionRealEstateAddress',
    });
  }

  convertPropertiesToDefinitions = (properties, definitions) =>
    Object.keys(properties).reduce((acc, cur) => {
      const definition = properties[cur].$ref
        ? definitions[properties[cur].$ref.split('/')[2]] // index "2" will get last part
        : properties[cur];
      return { ...acc, [cur]: definition };
    }, {});

  /**
   * @description Function to handle the submit of the form
   */
  handleSubmit = () => {
    this.setState({ submitted: true });
    this.form.submitButton.click();
  };

  /**
   * @description Function to handle errors of the form
   */
  handleError = errorsNumber => this.setState({ errors: errorsNumber });

  /**
   * @description Function to handle the cancel button onClick
   */
  handleCancel = () => {
    const { cancelNewOrder, intl } = this.props;

    const confMessage = intl.formatMessage({ id: 'confirmation.cancelNewOrder' });

    if (window.confirm(confMessage)) cancelNewOrder();
  };

  /**
   * @description Function to calculate the package consumption
   */
  calculatePackageConsume = () => {
    const { stepsFormData } = this.state;

    this.setState({ apiStatus: 'WORKING' });

    API.post('/financial/packageconsume', JSON.stringify(stepsFormData))
      .then(({ data }) => {
        if (data?.hasRequiredCredits) {
          this.setState(prevState => ({
            newOrderStatus: 'CONFIRM_ORDER',
            packageConsume: data,
            step: this.steps.length - 1,
            submitted: stepsFormData[this.steps[prevState.step + 1]] !== undefined,
          }));
        } else {
          this.setState(prevState => ({
            newOrderStatus: 'PURCHASE_PACKAGE',
            packageConsume: data,
            step: this.steps.length - 1,
            submitted: stepsFormData[this.steps[prevState.step + 1]] !== undefined,
          }));
        }

        this.setState({ apiStatus: 'IDLE' });
      })
      .catch(err => {
        console.debug('[DEBUG] calculatePackageConsume error ', err);

        this.setState({ apiStatus: 'ERROR' });
      });
  };

  /**
   * @description Function called to go to the next step of the form
   */
  nextStep = () => {
    const { step, stepsFormData } = this.state;

    switch (this.steps[step]) {
      case this.steps[this.steps.length - 2]:
        this.calculatePackageConsume();

        break;

      default:
        this.setState(state => ({
          step: state.step + 1,
          submitted: !!stepsFormData[this.steps[state.step + 1]],
        }));
    }
  };

  saveOrder = async () => {
    const { newOrderStatus, stepsFormData, genericWarningAccepted, unproductiveWarningAccepted } = this.state;
    const { history, newOrderType } = this.props;
    const me = this.context;

    if (
      newOrderStatus !== 'MARKETPLACE' &&
      newOrderStatus !== 'IMOBILIARIA_DIGITAL' &&
      this.features.indexOf('FORM_ACCESSINFO') > 0 &&
      !unproductiveWarningAccepted
    ) {
      this.setState({ submitted: true });
      return;
    }

    if (newOrderStatus === 'IMOBILIARIA_DIGITAL' && !genericWarningAccepted) {
      this.setState({ submitted: true });
      return;
    }

    if (newOrderType === 'marketplace_fichacerta') this.setState({ submitted: true });

    this.setState({ apiStatus: 'WORKING' });

    try {
      await API.post('/orders', JSON.stringify(stepsFormData));

      this.props.syncTags();
      this.setState({ apiStatus: 'IDLE' });

      const showSuccesPage = !!me?.disabledByModality;

      showSuccesPage ? history.push('/orders/success') : history.push('/orders');
    } catch (err) {
      console.debug('[DEBUG] saveOrder error ', err);

      if (err?.response?.data) {
        this.setState(prevState => ({
          apiStatus: 'IDLE',
          step: prevState.step - 1,
          errorMessage: err?.response?.data?.message || 'UNKNOWN_ERROR',
        }));
      } else {
        this.setState({ apiStatus: 'ERROR' });
      }
    }
  };

  purchasePackage = () => {
    const { selectedPackage } = this.state;

    if (selectedPackage !== '') {
      this.setState({ apiStatus: 'WORKING' });

      API.post(`/packages/${selectedPackage}/clients`)
        .then(() => {
          this.calculatePackageConsume();
          this.setState({ apiStatus: 'IDLE' });
        })
        .catch(err => {
          console.debug('[DEBUG] purchasePackage error ', err);

          this.setState({ apiStatus: 'ERROR' });
        });
    } else {
      this.saveOrder();
    }
  };

  onSubmit = () => this.nextStep();

  handleBack = () => this.setState(prevState => ({ step: prevState.step - 1, errors: 0 }));

  handleFetchRealEstateAddressesByClient = async () => {
    const me = this.context;

    if (!me?.identifier) return [];

    try {
      const { data } = await API.get(`/clients/${me?.identifier}/addresses`);

      return data?.data || [];
    } catch {
      return [];
    }
  };

  handleAddressesFieldsChange = async ({ formData, addressesFieldsAlreadyChanged, emptyFormDataCityValue }) => {
    const { stepsFormData } = this.state;

    // Address state field changes
    if (
      (formData.state && formData.state !== stepsFormData.address?.state) ||
      (formData.address?.state && formData.address.state !== stepsFormData.address?.state)
    ) {
      await this.handleStateFieldChange({
        stateName: formData.state || formData.address?.state,
        handleSetSelectedState: selectedState => this.setState({ addressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          const updatedCitiesAndNeighborhoods = {
            address: {
              properties: {
                city: {
                  enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                  enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                },
                neighborhood: {
                  enum: { $set: [] },
                  enumNames: { $set: [] },
                },
              },
            },
          };

          this.setState(state => ({
            addressCities: fetchedCities,
            addressNeighborhoods: [],
            addressSelectedCity: null,
            addressSelectedNeighborhood: null,

            schema: update(state.schema, { definitions: { ...updatedCitiesAndNeighborhoods } }),
            stepsSchema: update(state.stepsSchema, { ...updatedCitiesAndNeighborhoods }),
          }));

          emptyFormDataCityValue = true;
        },
      });
    }

    // Address city field changes
    if (
      (formData.city && formData.city !== stepsFormData.address?.city) ||
      (formData.address?.city && formData.address.city !== stepsFormData.address?.city)
    ) {
      await this.handleCityFieldChange({
        cityName: formData.city || formData.address?.city,
        citiesInState: this.state.addressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          const updatedNeighborhoods = {
            address: {
              properties: {
                neighborhood: {
                  enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                  enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                },
              },
            },
          };

          this.setState(state => ({
            addressNeighborhoods: fetchedNeighborhoods,
            addressSelectedCity: selectedCity,
            addressSelectedNeighborhood: null,

            schema: update(state.schema, { definitions: { ...updatedNeighborhoods } }),
            stepsSchema: update(state.stepsSchema, { ...updatedNeighborhoods }),
          }));
        },
      });
    }

    // Address neighborhood field changes
    if (
      (formData.neighborhood && formData.neighborhood !== stepsFormData.address?.neighborhood) ||
      (formData.address?.neighborhood && formData.address.neighborhood !== stepsFormData.address?.neighborhood)
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.neighborhood || formData.address?.neighborhood,
        neighborhoodsInState: this.state.addressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ addressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    // Address zipcode field change
    if (
      (formData.zipcode && formData.zipcode !== stepsFormData.address?.zipcode) ||
      (formData.address?.zipcode && formData.address.zipcode !== stepsFormData.address?.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.zipcode || formData.address?.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.zipcode || formData.address?.zipcode,
            street: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            number: formData.number || formData.address?.number,
            complement: formData.complement || formData.address?.complement,
          };

          const updatedCitiesAndNeighborhoods = {
            address: {
              properties: {
                city: {
                  enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                  enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                },
                neighborhood: {
                  enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                  enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                },
              },
            },
          };

          this.setState(state => ({
            addressCities: fetchedCities,
            addressNeighborhoods: fetchedNeighborhoods,
            addressSelectedState: selectedState,
            addressSelectedCity: selectedCity,
            addressSelectedNeighborhood: selectedNeighborhood,

            stepsFormData: { ...state.stepsFormData, address: updatedAddress },
            schema: update(state.schema, { definitions: { ...updatedCitiesAndNeighborhoods } }),
            stepsSchema: update(state.stepsSchema, { ...updatedCitiesAndNeighborhoods }),
          }));

          addressesFieldsAlreadyChanged = true;
        },
      });
    }

    return { addressesFieldsAlreadyChanged, emptyFormDataCityValue };
  };

  handleAccessInformationAddressesFieldsChange = async ({
    formData,
    schema,
    addressesFieldsAlreadyChanged,
    realEstateAddressAlreadyChanged,
    emptyFormDataCityValue,
  }) => {
    const { stepsFormData } = this.state;

    // Fills accessInformation address state select field
    if (
      !!schema.definitions?.address?.properties &&
      (formData.type === 'Na imobiliária' || formData.type === 'Outro') &&
      formData.type !== stepsFormData.accessInformation?.type
    ) {
      this.setState(state => ({
        stepsSchema: update(state.stepsSchema, {
          accessInformation: {
            properties: {
              address: {
                $set: {
                  properties: {
                    ...state.schema.definitions.address.properties,
                    state: {
                      enum: state.addressesStates?.map(option => option?.name) || [],
                      enumNames: state.addressesStates?.map(option => option?.name) || [],
                    },
                  },
                },
              },
            },
          },
        }),
      }));
    }

    // Unset accessInformation address fields on stepsSchema
    if (
      formData.type !== 'Na imobiliária' &&
      formData.type !== 'Outro' &&
      formData.type !== stepsFormData.accessInformation?.type
    ) {
      this.setState(state => ({
        stepsSchema: update(state.stepsSchema, {
          accessInformation: { properties: { $unset: ['address'] } },
        }),
      }));
    }

    // AccessInformation address state field changes
    if (formData.address?.state && formData.address.state !== stepsFormData.accessInformation?.address?.state) {
      await this.handleStateFieldChange({
        stateName: formData.address.state,
        handleSetSelectedState: selectedState =>
          this.setState({ accessInformationAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            accessInformationAddressCities: fetchedCities,
            accessInformationAddressNeighborhoods: [],
            accessInformationAddressSelectedCity: null,
            accessInformationAddressSelectedNeighborhood: null,

            stepsSchema: update(state.stepsSchema, {
              accessInformation: {
                properties: {
                  address: {
                    properties: {
                      city: {
                        enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                      },
                      neighborhood: {
                        enum: { $set: [] },
                        enumNames: { $set: [] },
                      },
                    },
                  },
                },
              },
            }),
          }));

          emptyFormDataCityValue = true;
        },
      });
    }

    // AccessInformation address city field changes
    if (formData.address?.city && formData.address.city !== stepsFormData.accessInformation?.address?.city) {
      await this.handleCityFieldChange({
        cityName: formData.address.city,
        citiesInState: this.state.accessInformationAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            accessInformationAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationAddressSelectedCity: selectedCity,
            accessInformationAddressSelectedNeighborhood: null,

            stepsSchema: update(state.stepsSchema, {
              accessInformation: {
                properties: {
                  address: {
                    properties: {
                      neighborhood: {
                        enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // AccessInformation address neighborhood field changes
    if (
      formData.address?.neighborhood &&
      formData.address.neighborhood !== stepsFormData.accessInformation?.address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.address.neighborhood,
        neighborhoodsInState: this.state.accessInformationAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ accessInformationAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    // AccessInformation address zidpcode field change
    if (formData.address?.zipcode && formData.address.zipcode !== stepsFormData.accessInformation?.address?.zipcode) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.address.zipcode,
            street: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            complement: formData.address?.complement || '',
            number: formData.address?.number || '',
          };

          this.setState(state => ({
            accessInformationAddressCities: fetchedCities,
            accessInformationAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationAddressSelectedState: selectedState,
            accessInformationAddressSelectedCity: selectedCity,
            accessInformationAddressSelectedNeighborhood: selectedNeighborhood,

            stepsFormData: {
              ...state.stepsFormData,
              accessInformation: { ...state.stepsFormData.accessInformation, address: updatedAddress },
            },

            stepsSchema: update(state.stepsSchema, {
              accessInformation: {
                properties: {
                  address: {
                    properties: {
                      city: {
                        enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                      },
                      neighborhood: {
                        enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsAlreadyChanged = true;
        },
      });
    }

    // Shows/hides accessInformation realEstateAddress select field
    if (formData.type && formData.type !== stepsFormData.accessInformation?.type) {
      if (formData.type === 'Na imobiliária') {
        const clientAddresses = await this.handleFetchRealEstateAddressesByClient();

        this.setState(state => ({
          realEstateAddresses: clientAddresses,
          changeAccessInformationAddressFieldsAutomatically: clientAddresses?.length > 0,
          uiSchema: {
            ...state.uiSchema,
            accessInformation: {
              ...state.uiSchema.accessInformation,
              realEstateAddress: {
                'ui:field': 'realEstateAdressesSelectField',
                'ui:options': clientAddresses,
                'ui:label': 'realEstateForKeysLocation',
                'ui:id': 'real-estate-address-select',
              },
            },
          },
        }));
      } else {
        this.setState(state => ({
          realEstateAddresses: [],
          changeAccessInformationAddressFieldsAutomatically: false,
          uiSchema: {
            ...state.uiSchema,
            accessInformation: {
              ...state.uiSchema.accessInformation,
              realEstateAddress: { 'ui:widget': 'hidden', 'ui:hidden': true },
            },
          },
        }));

        if (formData.realEstateAddress) delete formData.realEstateAddress;

        if (stepsFormData.accessInformation?.realEstateAddress) {
          delete stepsFormData.accessInformation.realEstateAddress;
        }
      }
    }

    // AccessInformation address realEstateAddress field change
    if (
      formData.realEstateAddress !== stepsFormData.accessInformation?.realEstateAddress &&
      formData.type === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.realEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        const updatedAddress = {
          zipcode: selectedAddress.zipcode,
          street: selectedAddress.street,
          neighborhood: selectedAddress.neighborhood,
          city: selectedAddress.city,
          state: selectedAddress.state,
          complement: selectedAddress.complement || '',
          number: selectedAddress.number || '',
        };

        this.setState(state => ({
          accessInformationAddressSelectedState: {
            label: selectedAddress.state || null,
            value: selectedAddress.state || null,
          },
          accessInformationAddressSelectedCity: {
            label: selectedAddress.city || null,
            value: selectedAddress.city || null,
          },
          accessInformationAddressSelectedNeighborhood: {
            label: selectedAddress.neighborhood || null,
            value: selectedAddress.neighborhood || null,
          },

          stepsFormData: {
            ...state.stepsFormData,
            accessInformation: {
              ...state.stepsFormData.accessInformation,
              address: updatedAddress,
              realEstateAddress: realEstateId,
            },
          },
        }));

        realEstateAddressAlreadyChanged = true;
      }
    }

    // Fills accessInformation keysDevolutionAddress state select field
    if (
      !!schema.definitions?.address?.properties &&
      (formData.keysDevolutionType === 'Na imobiliária' || formData.keysDevolutionType === 'Outro') &&
      formData.keysDevolutionType !== stepsFormData.accessInformation?.keysDevolutionType
    ) {
      this.setState(state => ({
        stepsSchema: update(state.stepsSchema, {
          accessInformation: {
            properties: {
              keysDevolutionAddress: {
                $set: {
                  properties: {
                    ...state.schema.definitions.address.properties,
                    state: {
                      enum: state.addressesStates?.map(option => option?.name) || [],
                      enumNames: state.addressesStates?.map(option => option?.name) || [],
                    },
                  },
                },
              },
            },
          },
        }),
      }));
    }

    // Unset accessInformation keysDevolutionAddress fields on stepsSchema
    if (
      formData.keysDevolutionType !== 'Na imobiliária' &&
      formData.keysDevolutionType !== 'Outro' &&
      formData.keysDevolutionType !== stepsFormData.accessInformation?.keysDevolutionType
    ) {
      this.setState(state => ({
        stepsSchema: update(state.stepsSchema, {
          accessInformation: { properties: { $unset: ['keysDevolutionAddress'] } },
        }),
      }));
    }

    // AccessInformation keysDevolutionAddress state field changes
    if (
      formData.keysDevolutionAddress?.state &&
      formData.keysDevolutionAddress.state !== stepsFormData.accessInformation?.keysDevolutionAddress?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.keysDevolutionAddress.state,
        handleSetSelectedState: selectedState =>
          this.setState({ accessInformationKeysDevolutionAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            accessInformationKeysDevolutionAddressCities: fetchedCities,
            accessInformationKeysDevolutionAddressNeighborhoods: [],
            accessInformationKeysDevolutionAddressSelectedCity: null,
            accessInformationKeysDevolutionAddressSelectedNeighborhood: null,

            stepsSchema: update(state.stepsSchema, {
              accessInformation: {
                properties: {
                  keysDevolutionAddress: {
                    properties: {
                      city: {
                        enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                      },
                      neighborhood: {
                        enum: { $set: [] },
                        enumNames: { $set: [] },
                      },
                    },
                  },
                },
              },
            }),
          }));

          emptyFormDataCityValue = true;
        },
      });
    }

    // AccessInformation keysDevolutionAddress city field changes
    if (
      formData.keysDevolutionAddress?.city &&
      formData.keysDevolutionAddress.city !== stepsFormData.accessInformation?.keysDevolutionAddress?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.keysDevolutionAddress.city,
        citiesInState: this.state.accessInformationKeysDevolutionAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            accessInformationKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationKeysDevolutionAddressSelectedCity: selectedCity,
            accessInformationKeysDevolutionAddressSelectedNeighborhood: null,

            stepsSchema: update(state.stepsSchema, {
              accessInformation: {
                properties: {
                  keysDevolutionAddress: {
                    properties: {
                      neighborhood: {
                        enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // AccessInformation keysDevolutionAddress neighborhood field changes
    if (
      formData.keysDevolutionAddress?.neighborhood &&
      formData.keysDevolutionAddress.neighborhood !==
        stepsFormData.accessInformation?.keysDevolutionAddress?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.keysDevolutionAddress.neighborhood,
        neighborhoodsInState: this.state.accessInformationKeysDevolutionAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ accessInformationKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    // AccessInformation keysDevolutionAddress zidpcode field change
    if (
      formData.keysDevolutionAddress?.zipcode &&
      formData.keysDevolutionAddress.zipcode !== stepsFormData.accessInformation?.keysDevolutionAddress?.zipcode
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.keysDevolutionAddress.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.keysDevolutionAddress.zipcode,
            street: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            complement: formData.keysDevolutionAddress?.complement || '',
            number: formData.keysDevolutionAddress?.number || '',
          };

          this.setState(state => ({
            accessInformationKeysDevolutionAddressCities: fetchedCities,
            accessInformationKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationKeysDevolutionAddressSelectedState: selectedState,
            accessInformationKeysDevolutionAddressSelectedCity: selectedCity,
            accessInformationKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,

            stepsFormData: {
              ...state.stepsFormData,
              accessInformation: { ...state.stepsFormData.accessInformation, keysDevolutionAddress: updatedAddress },
            },

            stepsSchema: update(state.stepsSchema, {
              accessInformation: {
                properties: {
                  keysDevolutionAddress: {
                    properties: {
                      city: {
                        enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                      },
                      neighborhood: {
                        enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsAlreadyChanged = true;
        },
      });
    }

    // Shows/hides accessInformation devolutionRealEstateAddress select field
    if (
      formData?.keysDevolutionType &&
      formData?.keysDevolutionType !== stepsFormData?.accessInformation?.keysDevolutionType
    ) {
      if (formData?.keysDevolutionType === 'Na imobiliária') {
        const clientAddresses = await this.handleFetchRealEstateAddressesByClient();

        this.setState(state => ({
          realEstateAddresses: clientAddresses,
          changeAccessInformationkeysDevolutionAddressFieldsAutomatically: clientAddresses?.length > 0,
          uiSchema: {
            ...state.uiSchema,
            accessInformation: {
              ...state.uiSchema.accessInformation,
              devolutionRealEstateAddress: {
                'ui:field': 'realEstateAdressesSelectField',
                'ui:options': clientAddresses,
                'ui:label': 'devolutionRealEstateForKeysLocation',
                'ui:id': 'devolutiion-real-estate-address-select',
              },
            },
          },
        }));
      } else {
        this.setState(state => ({
          realEstateAddresses: [],
          changeAccessInformationkeysDevolutionAddressFieldsAutomatically: false,
          uiSchema: {
            ...state.uiSchema,
            accessInformation: {
              ...state.uiSchema.accessInformation,
              devolutionRealEstateAddress: { 'ui:widget': 'hidden', 'ui:hidden': true },
            },
          },
        }));

        if (formData.devolutionRealEstateAddress) delete formData.devolutionRealEstateAddress;

        if (stepsFormData.accessInformation?.devolutionRealEstateAddress) {
          delete stepsFormData.accessInformation.devolutionRealEstateAddress;
        }
      }
    }

    // AccessInformation keysDevolutionAddress devolutionRealEstateAddress field change
    if (
      formData.devolutionRealEstateAddress !== stepsFormData.accessInformation?.devolutionRealEstateAddress &&
      formData.keysDevolutionType === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.devolutionRealEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        const updatedAddress = {
          zipcode: selectedAddress.zipcode,
          street: selectedAddress.street,
          neighborhood: selectedAddress.neighborhood,
          city: selectedAddress.city,
          state: selectedAddress.state,
          complement: selectedAddress.complement || '',
          number: selectedAddress.number || '',
        };

        this.setState(state => ({
          accessInformationKeysDevolutionAddressSelectedState: {
            label: selectedAddress.state || null,
            value: selectedAddress.state || null,
          },
          accessInformationKeysDevolutionAddressSelectedCity: {
            label: selectedAddress.city || null,
            value: selectedAddress.city || null,
          },
          accessInformationKeysDevolutionAddressSelectedNeighborhood: {
            label: selectedAddress.neighborhood || null,
            value: selectedAddress.neighborhood || null,
          },

          stepsFormData: {
            ...state.stepsFormData,
            accessInformation: {
              ...state.stepsFormData.accessInformation,
              keysDevolutionAddress: updatedAddress,
              devolutionRealEstateAddress: realEstateId,
            },
          },
        }));

        realEstateAddressAlreadyChanged = true;
      }
    }

    return { addressesFieldsAlreadyChanged, realEstateAddressAlreadyChanged, emptyFormDataCityValue };
  };

  handlePropertyAccessAddressesFieldsChange = async ({
    formData,
    schema,
    addressesFieldsAlreadyChanged,
    realEstateAddressAlreadyChanged,
    emptyFormDataCityValue,
  }) => {
    const { stepsFormData } = this.state;

    // Fills propertyAccess address state select field
    if (
      !!schema.definitions?.address?.properties &&
      (formData.propertyAccess?.type === 'Na imobiliária' || formData.propertyAccess?.type === 'Outro') &&
      formData.propertyAccess.type !== stepsFormData.propertyAccess?.type
    ) {
      this.setState(state => ({
        schema: update(state.schema, {
          definitions: {
            propertyAccess: {
              properties: {
                address: {
                  $set: {
                    properties: {
                      ...state.schema.definitions.address.properties,
                      state: {
                        enum: state.addressesStates?.map(option => option?.name) || [],
                        enumNames: state.addressesStates?.map(option => option?.name) || [],
                      },
                    },
                  },
                },
              },
            },
          },
        }),
      }));
    }

    // Unset propertyAccess address fields on stepsSchema
    if (
      formData.propertyAccess?.type !== 'Na imobiliária' &&
      formData.propertyAccess?.type !== 'Outro' &&
      formData.propertyAccess?.type !== stepsFormData.propertyAccess?.type
    ) {
      this.setState(state => ({
        schema: update(state.schema, {
          definitions: { propertyAccess: { properties: { $unset: ['address'] } } },
        }),
      }));
    }

    // PropertyAccess address state field changes
    if (
      formData.propertyAccess?.address?.state &&
      formData.propertyAccess.address.state !== stepsFormData.propertyAccess?.address?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.propertyAccess.address.state,
        handleSetSelectedState: selectedState => this.setState({ propertyAccessAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            propertyAccessAddressCities: fetchedCities,
            propertyAccessAddressNeighborhoods: [],
            propertyAccessAddressSelectedCity: null,
            propertyAccessAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    address: {
                      properties: {
                        city: {
                          enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                        },
                        neighborhood: {
                          enum: { $set: [] },
                          enumNames: { $set: [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          emptyFormDataCityValue = true;
        },
      });
    }

    // PropertyAccess address city field changes
    if (
      formData.propertyAccess?.address?.city &&
      formData.propertyAccess.address.city !== stepsFormData.propertyAccess?.address?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.propertyAccess.address.city,
        citiesInState: this.state.propertyAccessAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            propertyAccessAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessAddressSelectedCity: selectedCity,
            propertyAccessAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    address: {
                      properties: {
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // PropertyAccess address neighborhood field changes
    if (
      formData.propertyAccess?.address?.neighborhood &&
      formData.propertyAccess.address.neighborhood !== stepsFormData.propertyAccess?.address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.propertyAccess.address.neighborhood,
        neighborhoodsInState: this.state.propertyAccessAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ propertyAccessAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    // PropertyAccess address zidpcode field change
    if (
      formData.propertyAccess?.address?.zipcode &&
      formData.propertyAccess.address.zipcode !== stepsFormData.propertyAccess?.address?.zipcode
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.propertyAccess.address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.propertyAccess.address.zipcode,
            street: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            complement: formData.propertyAccess.address?.complement || '',
            number: formData.propertyAccess.address?.number || '',
          };

          this.setState(state => ({
            propertyAccessAddressCities: fetchedCities,
            propertyAccessAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessAddressSelectedState: selectedState,
            propertyAccessAddressSelectedCity: selectedCity,
            propertyAccessAddressSelectedNeighborhood: selectedNeighborhood,

            stepsFormData: {
              ...state.stepsFormData,
              propertyAccess: { ...state.stepsFormData.propertyAccess, address: updatedAddress },
            },

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    address: {
                      properties: {
                        city: {
                          enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                        },
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsAlreadyChanged = true;
        },
      });
    }

    // Shows/hides propertyAccess realEstateAddress select field
    if (formData.propertyAccess?.type && formData.propertyAccess.type !== stepsFormData.propertyAccess?.type) {
      if (formData.propertyAccess.type === 'Na imobiliária') {
        const clientAddresses = await this.handleFetchRealEstateAddressesByClient();

        this.setState(state => ({
          realEstateAddresses: clientAddresses,
          changePropertyAccessAddressFieldsAutomatically: clientAddresses?.length > 0,
          uiSchema: {
            ...state.uiSchema,
            propertyAccess: {
              ...state.uiSchema.propertyAccess,
              realEstateAddress: {
                'ui:field': 'realEstateAdressesSelectField',
                'ui:options': clientAddresses,
                'ui:label': 'realEstateForKeysLocation',
                'ui:id': 'real-estate-address-select',
              },
            },
          },
        }));
      } else {
        this.setState(state => ({
          realEstateAddresses: [],
          changePropertyAccessAddressFieldsAutomatically: false,
          uiSchema: {
            ...state.uiSchema,
            propertyAccess: {
              ...state.uiSchema.propertyAccess,
              realEstateAddress: { 'ui:widget': 'hidden', 'ui:hidden': true },
            },
          },
        }));

        if (formData?.propertyAccess?.realEstateAddress) {
          delete formData.propertyAccess.realEstateAddress;
        }

        if (stepsFormData?.propertyAccess?.realEstateAddress) {
          delete stepsFormData.propertyAccess.realEstateAddress;
        }
      }
    }

    // PropertyAccess address realEstateAddress field change
    if (
      formData.propertyAccess?.realEstateAddress !== stepsFormData.propertyAccess?.realEstateAddress &&
      formData.propertyAccess?.type === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.propertyAccess?.realEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        const updatedAddress = {
          zipcode: selectedAddress.zipcode,
          street: selectedAddress.street,
          neighborhood: selectedAddress.neighborhood,
          city: selectedAddress.city,
          state: selectedAddress.state,
          complement: selectedAddress.complement || '',
          number: selectedAddress.number || '',
        };

        this.setState(state => ({
          propertyAccessAddressSelectedState: {
            label: selectedAddress.state || null,
            value: selectedAddress.state || null,
          },
          propertyAccessAddressSelectedCity: {
            label: selectedAddress.city || null,
            value: selectedAddress.city || null,
          },
          propertyAccessAddressSelectedNeighborhood: {
            label: selectedAddress.neighborhood || null,
            value: selectedAddress.neighborhood || null,
          },

          stepsFormData: {
            ...state.stepsFormData,
            propertyAccess: {
              ...state.stepsFormData.propertyAccess,
              address: updatedAddress,
              realEstateAddress: realEstateId,
            },
          },
        }));

        realEstateAddressAlreadyChanged = true;
      }
    }

    // Fills propertyAccess keysDevolutionAddress state select field
    if (
      !!schema.definitions?.address?.properties &&
      (formData.propertyAccess?.keysDevolutionType === 'Na imobiliária' ||
        formData.propertyAccess?.keysDevolutionType === 'Outro') &&
      formData.propertyAccess.keysDevolutionType !== stepsFormData.propertyAccess?.keysDevolutionType
    ) {
      this.setState(state => ({
        schema: update(state.schema, {
          definitions: {
            propertyAccess: {
              properties: {
                keysDevolutionAddress: {
                  $set: {
                    properties: {
                      ...state.schema.definitions.address.properties,
                      state: {
                        enum: state.addressesStates?.map(option => option?.name) || [],
                        enumNames: state.addressesStates?.map(option => option?.name) || [],
                      },
                    },
                  },
                },
              },
            },
          },
        }),
      }));
    }

    // Unset propertyAccess keysDevolutionAddress fields on stepsSchema
    if (
      formData.propertyAccess?.keysDevolutionType !== 'Na imobiliária' &&
      formData.propertyAccess?.keysDevolutionType !== 'Outro' &&
      formData.propertyAccess?.keysDevolutionType !== stepsFormData.propertyAccess?.keysDevolutionType
    ) {
      this.setState(state => ({
        schema: update(state.schema, {
          definitions: { propertyAccess: { properties: { $unset: ['keysDevolutionAddress'] } } },
        }),
      }));
    }

    // PropertyAccess keysDevolutionAddress state field changes
    if (
      formData.propertyAccess?.keysDevolutionAddress?.state &&
      formData.propertyAccess.keysDevolutionAddress.state !== stepsFormData.propertyAccess?.keysDevolutionAddress?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.propertyAccess.keysDevolutionAddress.state,
        handleSetSelectedState: selectedState =>
          this.setState({ propertyAccessKeysDevolutionAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            propertyAccessKeysDevolutionAddressCities: fetchedCities,
            propertyAccessKeysDevolutionAddressNeighborhoods: [],
            propertyAccessKeysDevolutionAddressSelectedCity: null,
            propertyAccessKeysDevolutionAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    keysDevolutionAddress: {
                      properties: {
                        city: {
                          enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                        },
                        neighborhood: {
                          enum: { $set: [] },
                          enumNames: { $set: [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          emptyFormDataCityValue = true;
        },
      });
    }

    // PropertyAccess keysDevolutionAddress city field changes
    if (
      formData.propertyAccess?.keysDevolutionAddress?.city &&
      formData.propertyAccess.keysDevolutionAddress.city !== stepsFormData.propertyAccess?.keysDevolutionAddress?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.propertyAccess.keysDevolutionAddress.city,
        citiesInState: this.state.propertyAccessKeysDevolutionAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            propertyAccessKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessKeysDevolutionAddressSelectedCity: selectedCity,
            propertyAccessKeysDevolutionAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    keysDevolutionAddress: {
                      properties: {
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // PropertyAccess keysDevolutionAddress neighborhood field changes
    if (
      formData.propertyAccess?.keysDevolutionAddress?.neighborhood &&
      formData.propertyAccess.keysDevolutionAddress.neighborhood !==
        stepsFormData.propertyAccess?.keysDevolutionAddress?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.propertyAccess.keysDevolutionAddress.neighborhood,
        neighborhoodsInState: this.state.propertyAccessKeysDevolutionAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ propertyAccessKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    // PropertyAccess keysDevolutionAddress zidpcode field change
    if (
      formData.propertyAccess?.keysDevolutionAddress?.zipcode &&
      formData.propertyAccess.keysDevolutionAddress.zipcode !==
        stepsFormData.propertyAccess?.keysDevolutionAddress?.zipcode
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.propertyAccess.keysDevolutionAddress.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.propertyAccess.keysDevolutionAddress.zipcode,
            street: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            complement: formData.propertyAccess.keysDevolutionAddress?.complement || '',
            number: formData.propertyAccess.keysDevolutionAddress?.number || '',
          };

          this.setState(state => ({
            propertyAccessKeysDevolutionAddressCities: fetchedCities,
            propertyAccessKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessKeysDevolutionAddressSelectedState: selectedState,
            propertyAccessKeysDevolutionAddressSelectedCity: selectedCity,
            propertyAccessKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,

            stepsFormData: {
              ...state.stepsFormData,
              propertyAccess: { ...state.stepsFormData.propertyAccess, keysDevolutionAddress: updatedAddress },
            },

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    keysDevolutionAddress: {
                      properties: {
                        city: {
                          enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                        },
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsAlreadyChanged = true;
        },
      });
    }

    // Shows/hides propertyAccess devolutionRealEstateAddress select field
    if (
      formData.propertyAccess?.keysDevolutionType &&
      formData.propertyAccess.keysDevolutionType !== stepsFormData.propertyAccess?.keysDevolutionType
    ) {
      if (formData.propertyAccess.keysDevolutionType === 'Na imobiliária') {
        const clientAddresses = await this.handleFetchRealEstateAddressesByClient();

        this.setState(state => ({
          realEstateAddresses: clientAddresses,
          changePropertyAccesskeysDevolutionAddressFieldsAutomatically: clientAddresses?.length > 0,
          uiSchema: {
            ...state.uiSchema,
            propertyAccess: {
              ...state.uiSchema.propertyAccess,
              devolutionRealEstateAddress: {
                'ui:field': 'realEstateAdressesSelectField',
                'ui:options': clientAddresses,
                'ui:label': 'devolutionRealEstateForKeysLocation',
                'ui:id': 'devolutiion-real-estate-address-select',
              },
            },
          },
        }));
      } else {
        this.setState(state => ({
          realEstateAddresses: [],
          changePropertyAccesskeysDevolutionAddressFieldsAutomatically: false,
          uiSchema: {
            ...state.uiSchema,
            propertyAccess: {
              ...state.uiSchema.propertyAccess,
              devolutionRealEstateAddress: { 'ui:widget': 'hidden', 'ui:hidden': true },
            },
          },
        }));

        if (formData.propertyAccess?.devolutionRealEstateAddress) {
          delete formData.propertyAccess.devolutionRealEstateAddress;
        }

        if (stepsFormData.propertyAccess?.devolutionRealEstateAddress) {
          delete stepsFormData.propertyAccess.devolutionRealEstateAddress;
        }
      }
    }

    // PropertyAccess keysDevolutionAddress devolutionRealEstateAddress field change
    if (
      formData.propertyAccess?.devolutionRealEstateAddress !==
        stepsFormData.propertyAccess?.devolutionRealEstateAddress &&
      formData.propertyAccess?.keysDevolutionType === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.propertyAccess?.devolutionRealEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        const updatedAddress = {
          zipcode: selectedAddress.zipcode,
          street: selectedAddress.street,
          neighborhood: selectedAddress.neighborhood,
          city: selectedAddress.city,
          state: selectedAddress.state,
          complement: selectedAddress.complement || '',
          number: selectedAddress.number || '',
        };

        this.setState(state => ({
          propertyAccessKeysDevolutionAddressSelectedState: {
            label: selectedAddress.state || null,
            value: selectedAddress.state || null,
          },
          propertyAccessKeysDevolutionAddressSelectedCity: {
            label: selectedAddress.city || null,
            value: selectedAddress.city || null,
          },
          propertyAccessKeysDevolutionAddressSelectedNeighborhood: {
            label: selectedAddress.neighborhood || null,
            value: selectedAddress.neighborhood || null,
          },

          stepsFormData: {
            ...state.stepsFormData,
            propertyAccess: {
              ...state.stepsFormData.propertyAccess,
              keysDevolutionAddress: updatedAddress,
              devolutionRealEstateAddress: realEstateId,
            },
          },
        }));

        realEstateAddressAlreadyChanged = true;
      }
    }

    return { addressesFieldsAlreadyChanged, realEstateAddressAlreadyChanged, emptyFormDataCityValue };
  };

  handleDesiredPropertyAddressesFieldsChange = async ({ formData, addressesFieldsAlreadyChanged }) => {
    const { stepsFormData } = this.state;

    // DesiredProperty state field changes
    if (
      formData?.desired_property?.address?.state &&
      formData.desired_property.address.state !== stepsFormData?.desired_property?.address?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.desired_property.address.state,
        handleSetSelectedState: selectedState => {
          this.setState({ desiredPropertyAddressSelectedState: selectedState });
        },
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            desiredPropertyAddressCities: fetchedCities,
            desiredPropertyAddressNeighborhoods: [],
            desiredPropertyAddressSelectedCity: null,
            desiredPropertyAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                desired_property_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: [] },
                      enumNames: { $set: [] },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // DesiredProperty city field changes
    if (
      formData?.desired_property?.address?.city &&
      formData.desired_property.address.city !== stepsFormData?.desired_property?.address?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.desired_property.address.city,
        citiesInState: this.state.desiredPropertyAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            desiredPropertyAddressNeighborhoods: fetchedNeighborhoods,
            desiredPropertyAddressSelectedCity: selectedCity,
            desiredPropertyAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                desired_property_address: {
                  properties: {
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // DesiredProperty neighborhood field changes
    if (
      formData?.desired_property?.address?.neighborhood &&
      formData.desired_property.address.neighborhood !== stepsFormData?.desired_property?.address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.desired_property.address.neighborhood,
        neighborhoodsInState: this.state.desiredPropertyAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ desiredPropertyAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    // DesiredProperty zipcode field change
    if (
      formData?.desired_property?.address?.zipcode &&
      formData.desired_property.address.zipcode !== stepsFormData?.desired_property?.address?.zipcode
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.desired_property.address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.desired_property.address.zipcode,
            street: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            number: formData.desired_property.address.number,
            complement: formData.desired_property.address.complement,
          };

          this.setState(state => ({
            desiredPropertyAddressCities: fetchedCities,
            desiredPropertyAddressNeighborhoods: fetchedNeighborhoods,
            desiredPropertyAddressSelectedState: selectedState,
            desiredPropertyAddressSelectedCity: selectedCity,
            desiredPropertyAddressSelectedNeighborhood: selectedNeighborhood,

            stepsFormData: {
              ...state.stepsFormData,
              desired_property: { ...state.stepsFormData.address, address: updatedAddress },
            },
            schema: update(state.schema, {
              definitions: {
                desired_property_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsAlreadyChanged = true;
        },
      });
    }

    return { addressesFieldsAlreadyChanged };
  };

  handleMainSuitorAddressesFieldsChange = async ({ formData, addressesFieldsAlreadyChanged }) => {
    const { stepsFormData } = this.state;

    // MainSuitorAddress state field changes
    if (
      formData?.main_suitor_address?.state &&
      formData.main_suitor_address.state !== stepsFormData?.main_suitor_address?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.main_suitor_address.state,
        handleSetSelectedState: selectedState => {
          this.setState({ mainSuitorAddressSelectedState: selectedState });
        },
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            mainSuitorAddressCities: fetchedCities,
            mainSuitorAddressNeighborhoods: [],
            mainSuitorAddressSelectedCity: null,
            mainSuitorAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                main_suitor_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: [] },
                      enumNames: { $set: [] },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // MainSuitorAddress city field changes
    if (
      formData?.main_suitor_address?.city &&
      formData.main_suitor_address.city !== stepsFormData?.main_suitor_address?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.main_suitor_address.city,
        citiesInState: this.state.mainSuitorAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            mainSuitorAddressNeighborhoods: fetchedNeighborhoods,
            mainSuitorAddressSelectedCity: selectedCity,
            mainSuitorAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                main_suitor_address: {
                  properties: {
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // MainSuitorAddress neighborhood field changes
    if (
      formData?.main_suitor_address?.neighborhood &&
      formData.main_suitor_address.neighborhood !== stepsFormData?.main_suitor_address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.main_suitor_address.neighborhood,
        neighborhoodsInState: this.state.mainSuitorAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ mainSuitorAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    // MainSuitorAddress zipcode field change
    if (
      formData?.main_suitor_address?.zipcode &&
      formData.main_suitor_address.zipcode !== stepsFormData?.main_suitor_address?.zipcode
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.main_suitor_address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.main_suitor_address.zipcode,
            street: address.street,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            number: formData.main_suitor_address.number,
            complement: formData.main_suitor_address.complement,
          };

          this.setState(state => ({
            mainSuitorAddressCities: fetchedCities,
            mainSuitorAddressNeighborhoods: fetchedNeighborhoods,
            mainSuitorAddressSelectedState: selectedState,
            mainSuitorAddressSelectedCity: selectedCity,
            mainSuitorAddressSelectedNeighborhood: selectedNeighborhood,

            stepsFormData: { ...state.stepsFormData, main_suitor_address: updatedAddress },
            schema: update(state.schema, {
              definitions: {
                main_suitor_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsAlreadyChanged = true;
        },
      });
    }

    return { addressesFieldsAlreadyChanged };
  };

  /**
   * @description Function to handle the change of the fields of the form
   */
  handleChange = async ({ errors, formData, schema }) => {
    const { formUsesRootSchemaAsMainSchema, priceCalculated, stepsFormData } = this.state;
    const { newOrderType } = this.props;

    let calculatePrice = false;
    let addressesFieldsAlreadyChanged = false;
    let realEstateAddressAlreadyChanged = false;
    let emptyFormDataCityValue = false;

    let formDataChangedProperties = {};

    if (formUsesRootSchemaAsMainSchema) {
      formDataChangedProperties = this.handleGetChangedProperties(stepsFormData, formData);
    }

    // Control the data that change the price
    if (schema.title === 'additionals' || schema.title === 'budgetDuringInspection') {
      if (
        (formData?.modality && formData.modality !== stepsFormData?.additionals?.modality) ||
        (formData?.urgency && formData.urgency !== stepsFormData?.additionals?.urgency)
      ) {
        calculatePrice = true;
      }

      // If selected budgetDuringInspection.includeBudget option is "simple" or "termination'
      if (
        !!formData?.includeBudget &&
        formData.includeBudget !== 'no' &&
        formData.includeBudget !== stepsFormData?.budgetDuringInspection?.includeBudget
      ) {
        calculatePrice = true;
      }

      // If selected budgetDuringInspection.includeBudget option is "no"
      if (
        formData?.includeBudget === 'no' &&
        !!stepsFormData?.budgetDuringInspection?.includeBudget &&
        formData.includeBudget !== stepsFormData.budgetDuringInspection.includeBudget
      ) {
        calculatePrice = true;
      }
    }

    if (formData.area !== undefined && formData.building_type !== undefined && formData.furnished !== undefined) {
      if (priceCalculated) {
        if (
          formData.area !== stepsFormData.details.area ||
          formData.building_type !== stepsFormData.details.building_type ||
          formData.furnished !== stepsFormData.details.furnished
        ) {
          calculatePrice = true;
        }
      } else {
        calculatePrice = true;
      }
    }

    // Address/KeysDevolutionAddress schema changes
    if (
      schema.title === 'address' ||
      Object.keys(formDataChangedProperties).find(propertyName => propertyName.startsWith('address.'))
    ) {
      const fieldsChangeFlags = await this.handleAddressesFieldsChange({
        formData,
        schema,
        addressesFieldsAlreadyChanged,
        emptyFormDataCityValue,
      });

      addressesFieldsAlreadyChanged = fieldsChangeFlags.addressesFieldsAlreadyChanged;
      emptyFormDataCityValue = fieldsChangeFlags.emptyFormDataCityValue;
    }

    // AccessInformation address/keysDevolutionAddress schema changes
    if (schema.title === 'accessInformation') {
      const fieldsChangeFlags = await this.handleAccessInformationAddressesFieldsChange({
        formData,
        schema,
        addressesFieldsAlreadyChanged,
        realEstateAddressAlreadyChanged,
        emptyFormDataCityValue,
      });

      addressesFieldsAlreadyChanged = fieldsChangeFlags.addressesFieldsAlreadyChanged;
      realEstateAddressAlreadyChanged = fieldsChangeFlags.realEstateAddressAlreadyChanged;
      emptyFormDataCityValue = fieldsChangeFlags.emptyFormDataCityValue;
    }

    // PropertyAccess address/keysDevolutionAddress schema changes
    if (Object.keys(formDataChangedProperties).find(propertyName => propertyName.startsWith('propertyAccess.'))) {
      const fieldsChangeFlags = await this.handlePropertyAccessAddressesFieldsChange({
        formData,
        schema,
        addressesFieldsAlreadyChanged,
        realEstateAddressAlreadyChanged,
        emptyFormDataCityValue,
      });

      addressesFieldsAlreadyChanged = fieldsChangeFlags.addressesFieldsAlreadyChanged;
      realEstateAddressAlreadyChanged = fieldsChangeFlags.realEstateAddressAlreadyChanged;
      emptyFormDataCityValue = fieldsChangeFlags.emptyFormDataCityValue;
    }

    // DesiredProperty address schema changes
    if (
      Object.keys(formDataChangedProperties).find(propertyName => propertyName.startsWith('desired_property.address'))
    ) {
      const fieldsChangeFlags = await this.handleDesiredPropertyAddressesFieldsChange({
        formData,
        schema,
        addressesFieldsAlreadyChanged,
      });

      addressesFieldsAlreadyChanged = fieldsChangeFlags.addressesFieldsAlreadyChanged;
    }

    // Main address schema changes
    if (Object.keys(formDataChangedProperties).find(propertyName => propertyName.startsWith('main_suitor_address.'))) {
      const fieldsChangeFlags = await this.handleMainSuitorAddressesFieldsChange({
        formData,
        schema,
        addressesFieldsAlreadyChanged,
      });

      addressesFieldsAlreadyChanged = fieldsChangeFlags.addressesFieldsAlreadyChanged;
    }

    const addressDataToEmpty = {};

    if (emptyFormDataCityValue && !formUsesRootSchemaAsMainSchema) addressDataToEmpty.city = null;

    if (schema.title !== 'attachments' && !addressesFieldsAlreadyChanged && !realEstateAddressAlreadyChanged) {
      if (!this.orderTypesWithNoNestedSchema.includes(newOrderType)) {
        this.setState({
          stepsFormData: {
            ...stepsFormData,
            [schema.title]: { ...stepsFormData[schema.title], ...formData, ...addressDataToEmpty },
          },
        });
      } else {
        this.setState({ stepsFormData: { ...formData, ...addressDataToEmpty } });
      }
    } else if (schema.title === 'attachments' && Array.isArray(formData)) {
      this.setState({ stepsFormData: { ...stepsFormData, attachments: formData } });
    }

    if (calculatePrice) this.calculateOrderPrice(formData);

    console.debug('[DEBUG] ClientOrderForm errors ', errors);

    this.handleError(errors?.length || 0);
  };

  handleZipcodeFieldChange = async ({ zipcode = null, address = {}, handleSetAddressDataInState }) => {
    try {
      if (!address?.['state_id'] || !address?.['city_id']) {
        address = await this.addressService.getLocationByZipcode(zipcode);

        if (!address) return;
      }

      const fetchedCities = await this.addressService.getCitiesByState(address?.['state_id']);
      const fetchedNeighborhoods = await this.addressService.getNeighborhoodsByCity(address?.['city_id']);

      const uniqueCities = removeDuplicates(fetchedCities || []);
      const uniqueNeighborhoods = removeDuplicates(fetchedNeighborhoods || []);

      const selectedState = this.state.addressesStates?.find(state => state?.id === address?.['state_id']);
      const selectedCity = uniqueCities?.find(city => city?.id === address?.['city_id']);

      let selectedNeighborhood = uniqueNeighborhoods?.find(
        neighborhood => neighborhood?.id === address?.neighborhood_id
      );

      if (!selectedNeighborhood) {
        selectedNeighborhood = uniqueNeighborhoods?.find(neighborhood => neighborhood?.name === address?.neighborhood);
      }

      handleSetAddressDataInState({
        address,
        fetchedCities: uniqueCities,
        fetchedNeighborhoods: uniqueNeighborhoods,
        selectedState: selectedState?.name ? { label: selectedState.name, value: selectedState.name } : null,
        selectedCity: selectedCity?.name ? { label: selectedCity.name, value: selectedCity.name } : null,
        selectedNeighborhood: selectedNeighborhood?.name
          ? { label: selectedNeighborhood.name, value: selectedNeighborhood.name }
          : null,
      });
    } catch (err) {
      console.debug(`[DEBUG] handleZipcodeFieldChange error with: ${JSON.stringify(address || {})} `, err);
    }
  };

  handleStateFieldChange = async ({ stateName, handleSetSelectedState, handleSetCitiesInState }) => {
    if (!stateName) return;

    const selectedState = this.state.addressesStates?.find(addressState => addressState?.name === stateName);

    if (selectedState?.id && selectedState?.name) {
      handleSetSelectedState({ label: selectedState.name, value: selectedState.name });

      const fetchedCities = await this.addressService.getCitiesByState(selectedState.id);

      handleSetCitiesInState(removeDuplicates(fetchedCities || []));
    } else {
      handleSetCitiesInState([]);
    }
  };

  handleCityFieldChange = async ({ cityName, citiesInState, handleSetNeighborhoodsInState }) => {
    if (!cityName) return;

    const selectedCity = citiesInState?.find(city => city?.name === cityName);

    if (selectedCity?.id && selectedCity?.name) {
      const fetchedNeighborhoods = await this.addressService.getNeighborhoodsByCity(selectedCity.id);

      handleSetNeighborhoodsInState({
        fetchedNeighborhoods: removeDuplicates(fetchedNeighborhoods || []),
        selectedCity: { label: selectedCity.name, value: selectedCity.name },
      });
    } else {
      handleSetNeighborhoodsInState({ fetchedNeighborhoods: [], selectedCity: null });
    }
  };

  handleNeighborhoodFieldChange = ({
    neighborhoodName,
    neighborhoodsInState,
    handleSetSelectedNeighborhoodInState,
  }) => {
    if (!neighborhoodName) return;

    const selectedNeighborhood = neighborhoodsInState?.find(neighborhood => neighborhood?.name === neighborhoodName);

    handleSetSelectedNeighborhoodInState(
      selectedNeighborhood?.name ? { label: selectedNeighborhood.name, value: selectedNeighborhood.name } : null
    );
  };

  /**
   * @description Produce a new object containing the properties that have changed in JsonSchema form-data
   */
  handleGetChangedProperties = (oldData, newData, path = '') => {
    const changedProperties = {};

    try {
      for (let key in oldData) {
        if (oldData.hasOwnProperty(key)) {
          const currentPath = path ? `${path}.${key}` : key;

          if (!newData.hasOwnProperty(key)) {
            changedProperties[currentPath] = { oldValue: oldData[key], newValue: undefined };
          } else if (typeof oldData[key] === 'object' && typeof newData[key] === 'object') {
            const nestedChanges = this.handleGetChangedProperties(oldData[key], newData[key], currentPath);

            Object.assign(changedProperties, nestedChanges);
          } else if (oldData[key] !== newData[key]) {
            changedProperties[currentPath] = { oldValue: oldData[key], newValue: newData[key] };
          }
        }
      }

      for (let key in newData) {
        if (newData.hasOwnProperty(key) && !oldData.hasOwnProperty(key)) {
          const currentPath = path ? `${path}.${key}` : key;

          changedProperties[currentPath] = { oldValue: undefined, newValue: newData[key] };
        }
      }

      return changedProperties;
    } catch {
      return {};
    }
  };

  calculateOrderPrice = formData => {
    const { step, stepsFormData } = this.state;

    try {
      this.setState({ priceCalculated: true });

      let formDataNoPrice = update(stepsFormData, { [this.steps[step]]: { $set: formData } });
      formDataNoPrice = update(formDataNoPrice, { details: { $unset: ['price'] } });

      API.post('/financial/orderprice', JSON.stringify(formDataNoPrice))
        .then(({ data }) => {
          switch (data?.description) {
            case 'STANDARD_METER':
              // Set the price to the given value
              this.handleAreaPrice(data?.value);
              break;

            case 'MONETARY':
              // Enable the field for user input
              this.handleEnableCustomPrice(data?.value, data?.minimalPrice);
              break;

            default:
              this.handleStringPrice();
          }
        })
        .catch(err => {
          console.debug('[DEBUG] POST /financial/orderprice API call error ', err);
        });
    } catch (err) {
      console.debug('[DEBUG] calculateOrderPrice error ', err);
    }
  };

  /**
   * @description Function to enable the price field, for custom price.
   */
  handleEnableCustomPrice = () => {
    const { priceType, stepsFormData, uiSchema: uiSchemaState } = this.state;

    const priceUI = {
      'ui:widget': 'customPriceWidget',
      'ui:options': { label: false },
    };

    if (priceType !== 'MONETARY') {
      this.setState({
        uiSchema: update(uiSchemaState, { details: { price: { $set: priceUI } } }),
        stepsFormData: update(stepsFormData, { details: { price: { $set: undefined } } }),
        priceType: 'MONETARY',
      });
    }
  };

  /**
   * @description Function to set the price field to be defined string.
   */
  handleStringPrice = () => {
    const { uiSchema: uiSchemaState } = this.state;

    const stringUI = {
      'ui:widget': 'customPriceStringWidget',
      'ui:options': { label: false },
    };

    this.setState({
      uiSchema: update(uiSchemaState, { details: { price: { $set: stringUI } } }),
      priceType: 'PENDING',
    });
  };

  /**
   * @description Function to set the price field to be in meters.
   */
  handleAreaPrice = value => {
    const { uiSchema: uiSchemaState, stepsFormData } = this.state;

    const stringUI = {
      'ui:widget': 'customPriceWidget',
      'ui:disabled': true,
      'ui:options': { unit: 'credits' },
    };

    this.setState({
      uiSchema: update(uiSchemaState, { details: { price: { $set: stringUI } } }),
      stepsFormData: update(stepsFormData, { details: { price: { $set: value } } }),
      priceType: 'STANDARD_METER',
    });
  };

  handleSelectPackage = event => this.setState({ selectedPackage: event.target.value });

  handleGenericCheckChange = value => this.setState({ genericWarningAccepted: value });

  handleUnproductiveCheckChange = value => this.setState({ unproductiveWarningAccepted: value });

  createOption = optionValue => ({ id: optionValue, name: optionValue, tokenized: optionValue });

  addressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.address?.properties?.neighborhood) return;

    const newOption = this.createOption(optionValue);

    const neighborhoodsWithNewOption = {
      address: {
        properties: {
          neighborhood: {
            enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
            enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
          },
        },
      },
    };

    this.setState(state => ({
      addressNeighborhoods: [newOption, ...state.addressNeighborhoods],
      addressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, { address: { neighborhood: { $set: newOption.name } } }),
      schema: update(state.schema, { definitions: { ...neighborhoodsWithNewOption } }),
      stepsSchema: update(state.stepsSchema, { ...neighborhoodsWithNewOption }),
    }));
  };

  accessInformationAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.stepsSchema?.accessInformation?.properties?.address?.properties?.neighborhood) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      accessInformationAddressNeighborhoods: [newOption, ...state.accessInformationAddressNeighborhoods],
      accessInformationAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        accessInformation: { address: { neighborhood: { $set: newOption.name } } },
      }),
      stepsSchema: update(state.stepsSchema, {
        accessInformation: {
          properties: {
            address: {
              properties: {
                neighborhood: {
                  enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                },
              },
            },
          },
        },
      }),
    }));
  };

  accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.stepsSchema?.accessInformation?.properties?.keysDevolutionAddress?.properties?.neighborhood) {
      return;
    }

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      accessInformationKeysDevolutionAddressNeighborhoods: [
        newOption,
        ...state.accessInformationKeysDevolutionAddressNeighborhoods,
      ],
      accessInformationKeysDevolutionAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        accessInformation: { keysDevolutionAddress: { neighborhood: { $set: newOption.name } } },
      }),
      stepsSchema: update(state.stepsSchema, {
        accessInformation: {
          properties: {
            keysDevolutionAddress: {
              properties: {
                neighborhood: {
                  enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                },
              },
            },
          },
        },
      }),
    }));
  };

  propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.propertyAccess?.properties?.address?.properties?.neighborhood) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      propertyAccessAddressNeighborhoods: [newOption, ...state.propertyAccessAddressNeighborhoods],
      propertyAccessAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        propertyAccess: { address: { neighborhood: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          propertyAccess: {
            properties: {
              address: {
                properties: {
                  neighborhood: {
                    enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                    enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  },
                },
              },
            },
          },
        },
      }),
    }));
  };

  propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.propertyAccess?.properties?.keysDevolutionAddress?.properties?.neighborhood) {
      return;
    }

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      propertyAccessKeysDevolutionAddressNeighborhoods: [
        newOption,
        ...state.propertyAccessKeysDevolutionAddressNeighborhoods,
      ],
      propertyAccessKeysDevolutionAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        propertyAccess: { keysDevolutionAddress: { neighborhood: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          propertyAccess: {
            properties: {
              keysDevolutionAddress: {
                properties: {
                  neighborhood: {
                    enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                    enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  },
                },
              },
            },
          },
        },
      }),
    }));
  };

  desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.desired_property_address?.properties?.neighborhood) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      desiredPropertyAddressNeighborhoods: [newOption, ...state.desiredPropertyAddressNeighborhoods],
      desiredPropertyAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        desired_property: { address: { neighborhood: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          desired_property_address: {
            properties: {
              neighborhood: {
                enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
              },
            },
          },
        },
      }),
    }));
  };

  mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.main_suitor_address?.properties?.neighborhood) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      mainSuitorAddressNeighborhoods: [newOption, ...state.mainSuitorAddressNeighborhoods],
      mainSuitorAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, { main_suitor_address: { neighborhood: { $set: newOption.name } } }),
      schema: update(state.schema, {
        definitions: {
          main_suitor_address: {
            properties: {
              neighborhood: {
                enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
              },
            },
          },
        },
      }),
    }));
  };

  addressCitySelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.address?.properties?.city) return;

    const newOption = this.createOption(optionValue);

    const cityNewOption = {
      address: {
        properties: {
          city: {
            enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
            enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
          },
        },
      },
    };

    this.setState(state => ({
      addressSelectedCity: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, { address: { city: { $set: newOption.name } } }),
      schema: update(state.schema, { definitions: { ...cityNewOption } }),
      stepsSchema: update(state.stepsSchema, { ...cityNewOption }),
    }));
  };

  accessInformationAddressCitySelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.stepsSchema?.accessInformation?.properties?.address?.properties?.city) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      accessInformationAddressSelectedCity: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        accessInformation: { address: { city: { $set: newOption.name } } },
      }),
      stepsSchema: update(state.stepsSchema, {
        accessInformation: {
          properties: {
            address: {
              properties: {
                city: {
                  enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                },
              },
            },
          },
        },
      }),
    }));
  };

  accessInformationKeysDevolutionAddressCitySelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.stepsSchema?.accessInformation?.properties?.keysDevolutionAddress?.properties?.city) {
      return;
    }

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      accessInformationKeysDevolutionAddressSelectedCity: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        accessInformation: { keysDevolutionAddress: { city: { $set: newOption.name } } },
      }),
      stepsSchema: update(state.stepsSchema, {
        accessInformation: {
          properties: {
            keysDevolutionAddress: {
              properties: {
                city: {
                  enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                },
              },
            },
          },
        },
      }),
    }));
  };

  propertyAccessAddressCitySelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.propertyAccess?.properties?.address?.properties?.city) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      propertyAccessAddressSelectedCity: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        propertyAccess: { address: { city: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          propertyAccess: {
            properties: {
              address: {
                properties: {
                  city: {
                    enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                    enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  },
                },
              },
            },
          },
        },
      }),
    }));
  };

  propertyAccessKeysDevolutionAddressCitySelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.propertyAccess?.properties?.keysDevolutionAddress?.properties?.city) {
      return;
    }

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      propertyAccessKeysDevolutionAddressSelectedCity: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        propertyAccess: { keysDevolutionAddress: { city: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          propertyAccess: {
            properties: {
              keysDevolutionAddress: {
                properties: {
                  city: {
                    enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                    enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                  },
                },
              },
            },
          },
        },
      }),
    }));
  };

  desiredPropertyAddressCitySelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.desired_property_address?.properties?.city) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      desiredPropertyAddressSelectedCity: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, {
        desired_property: { address: { city: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          desired_property_address: {
            properties: {
              city: {
                enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
              },
            },
          },
        },
      }),
    }));
  };

  mainSuitorAddressCitySelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.main_suitor_address?.properties?.city) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      mainSuitorAddressSelectedCity: { label: newOption.name, value: newOption.name },
      stepsFormData: update(state.stepsFormData, { main_suitor_address: { city: { $set: newOption.name } } }),
      schema: update(state.schema, {
        definitions: {
          main_suitor_address: {
            properties: {
              city: {
                enum: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
                enumNames: { $apply: items => (items ? [newOption.name, ...items] : [newOption.name]) },
              },
            },
          },
        },
      }),
    }));
  };

  render() {
    const {
      apiStatus,
      errorMessage,
      errors,
      loading,
      newOrderStatus,
      packageConsume,
      schema,
      stepsSchema,
      selectedPackage,
      formUsesRootSchemaAsMainSchema,
      step,
      stepsFormData,
      submitted,
      uiSchema: uiSchemaState,

      addressSelectedState,
      addressSelectedCity,
      addressSelectedNeighborhood,

      accessInformationAddressSelectedState,
      accessInformationAddressSelectedCity,
      accessInformationAddressSelectedNeighborhood,

      propertyAccessAddressSelectedState,
      propertyAccessAddressSelectedCity,
      propertyAccessAddressSelectedNeighborhood,

      accessInformationKeysDevolutionAddressSelectedState,
      accessInformationKeysDevolutionAddressSelectedCity,
      accessInformationKeysDevolutionAddressSelectedNeighborhood,

      propertyAccessKeysDevolutionAddressSelectedState,
      propertyAccessKeysDevolutionAddressSelectedCity,
      propertyAccessKeysDevolutionAddressSelectedNeighborhood,

      desiredPropertyAddressSelectedState,
      desiredPropertyAddressSelectedCity,
      desiredPropertyAddressSelectedNeighborhood,

      mainSuitorAddressSelectedState,
      mainSuitorAddressSelectedCity,
      mainSuitorAddressSelectedNeighborhood,
    } = this.state;
    const { intl, newOrderType, updateOrderItem } = this.props;

    if (!this.steps?.length || loading) return <Loading clientPanel />;

    let handleSecondButton = this.handleSubmit;
    let textSecondButton = 'BUTTON.NEXT';
    let content;

    const clientOrderFormDefaultProps = {
      submitted,
      updateOrderItem,
      handleError: this.handleError,
      handleChange: this.handleChange,
      onSubmit: this.onSubmit,
      intl,
      newOrderType,
      widgets: makeWidgets({
        addressSelectedState,
        addressSelectedCity,
        addressSelectedNeighborhood,

        accessInformationAddressSelectedState,
        accessInformationAddressSelectedCity,
        accessInformationAddressSelectedNeighborhood,

        propertyAccessAddressSelectedState,
        propertyAccessAddressSelectedCity,
        propertyAccessAddressSelectedNeighborhood,

        accessInformationKeysDevolutionAddressSelectedState,
        accessInformationKeysDevolutionAddressSelectedCity,
        accessInformationKeysDevolutionAddressSelectedNeighborhood,

        propertyAccessKeysDevolutionAddressSelectedState,
        propertyAccessKeysDevolutionAddressSelectedCity,
        propertyAccessKeysDevolutionAddressSelectedNeighborhood,

        desiredPropertyAddressSelectedState,
        desiredPropertyAddressSelectedCity,
        desiredPropertyAddressSelectedNeighborhood,

        mainSuitorAddressSelectedState,
        mainSuitorAddressSelectedCity,
        mainSuitorAddressSelectedNeighborhood,

        addressNeighborhoodSelectWidgetOnCreateOption: this.addressNeighborhoodSelectWidgetOnCreateOption,
        accessInformationAddressNeighborhoodSelectWidgetOnCreateOption:
          this.accessInformationAddressNeighborhoodSelectWidgetOnCreateOption,
        accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption:
          this.accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption,
        propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption:
          this.propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption,
        propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption:
          this.propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption,
        desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption:
          this.desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption,
        mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption:
          this.mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption,

        addressCitySelectWidgetOnCreateOption: this.addressCitySelectWidgetOnCreateOption,
        accessInformationAddressCitySelectWidgetOnCreateOption:
          this.accessInformationAddressCitySelectWidgetOnCreateOption,
        accessInformationKeysDevolutionAddressCitySelectWidgetOnCreateOption:
          this.accessInformationKeysDevolutionAddressCitySelectWidgetOnCreateOption,
        propertyAccessAddressCitySelectWidgetOnCreateOption: this.propertyAccessAddressCitySelectWidgetOnCreateOption,
        propertyAccessKeysDevolutionAddressCitySelectWidgetOnCreateOption:
          this.propertyAccessKeysDevolutionAddressCitySelectWidgetOnCreateOption,
        desiredPropertyAddressCitySelectWidgetOnCreateOption: this.desiredPropertyAddressCitySelectWidgetOnCreateOption,
        mainSuitorAddressCitySelectWidgetOnCreateOption: this.mainSuitorAddressCitySelectWidgetOnCreateOption,
      }),
    };

    if (formUsesRootSchemaAsMainSchema) {
      content = (
        <ClientOrderForm
          ref={form => {
            this.form = form;
          }}
          formData={stepsFormData}
          schema={schema}
          uiSchema={uiSchemaState}
          {...clientOrderFormDefaultProps}
          onSubmit={this.saveOrder}
        />
      );

      textSecondButton = 'BUTTON.SAVE';

      if (newOrderStatus === 'IMOBILIARIA_DIGITAL') {
        handleSecondButton = this.handleSubmit;

        content = [
          content,
          <GenericWarning key="generic-warning" onChange={this.handleGenericCheckChange} submitted={submitted} />,
        ];
      } else {
        handleSecondButton = this.saveOrder;
      }
    } else {
      if (
        this.steps[step] === 'additionals' &&
        stepsSchema?.budgetDuringInspection &&
        this.hasBudgetDuringInspectionSectionAllowed
      ) {
        content = (
          <ClientOrderForm
            ref={form => {
              this.form = form;
            }}
            formData={stepsFormData.budgetDuringInspection}
            schema={stepsSchema.budgetDuringInspection}
            uiSchema={uiSchemaState.budgetDuringInspection}
            key={stepsSchema.budgetDuringInspection?.title}
            {...clientOrderFormDefaultProps}
          />
        );

        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData[this.steps[step]]}
            schema={stepsSchema[this.steps[step]]}
            uiSchema={uiSchemaState[this.steps[step]]}
            key={this.steps[step]}
            {...clientOrderFormDefaultProps}
          />,
        ];
      } else {
        content = (
          <ClientOrderForm
            ref={form => {
              this.form = form;
            }}
            formData={stepsFormData[this.steps[step]]}
            schema={stepsSchema[this.steps[step]]}
            uiSchema={uiSchemaState[this.steps[step]]}
            key={this.steps[step]}
            {...clientOrderFormDefaultProps}
          />
        );
      }

      if (this.steps[step] === 'details' && stepsSchema?.attachments) {
        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData.attachments}
            schema={stepsSchema.attachments}
            uiSchema={uiSchemaState?.attachments}
            key={stepsSchema.attachments?.title}
            {...clientOrderFormDefaultProps}
          />,
        ];
      }

      if (this.steps[step] === 'details' && stepsSchema?.tags) {
        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData.tags}
            schema={stepsSchema.tags}
            uiSchema={uiSchemaState.tags}
            key={stepsSchema.tags?.title}
            {...clientOrderFormDefaultProps}
          />,
        ];
      }

      if (this.steps[step] === 'additionals' && stepsSchema?.recommendation) {
        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData.recommendation}
            schema={stepsSchema.recommendation}
            uiSchema={uiSchemaState.recommendation}
            key={stepsSchema.recommendation?.title}
            {...clientOrderFormDefaultProps}
          />,
        ];
      }

      if (this.steps[step] === 'additionals' && stepsSchema?.reportSubmission) {
        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData.reportSubmission}
            schema={stepsSchema.reportSubmission}
            uiSchema={uiSchemaState.reportSubmission}
            key={stepsSchema.reportSubmission?.title}
            {...clientOrderFormDefaultProps}
          />,
        ];
      }

      if (this.steps[step] === 'additionals' && stepsSchema?.vacationNotice && newOrderType === 'saida') {
        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData.vacationNotice}
            schema={stepsSchema.vacationNotice}
            uiSchema={uiSchemaState.vacationNotice}
            key={stepsSchema.vacationNotice?.title}
            {...clientOrderFormDefaultProps}
          />,
        ];
      }

      if (this.steps[step] === 'accessInformation' && stepsSchema?.accompaniedInspection) {
        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData.accompaniedInspection}
            schema={stepsSchema.accompaniedInspection}
            uiSchema={uiSchemaState.accompaniedInspection}
            key={stepsSchema.accompaniedInspection?.title}
            {...clientOrderFormDefaultProps}
          />,
        ];
      }

      if (this.steps[step] === 'accessInformation' && stepsSchema?.tourAccessInfo) {
        content = [
          content,
          <ClientOrderForm
            formData={stepsFormData.tourAccessInfo}
            schema={stepsSchema.tourAccessInfo}
            uiSchema={uiSchemaState.tourAccessInfo}
            key={stepsSchema.tourAccessInfo?.title}
            {...clientOrderFormDefaultProps}
          />,
        ];
      }
    }

    if (this.steps[step] === 'payment') {
      switch (newOrderStatus) {
        case 'PURCHASE_PACKAGE':
          handleSecondButton = this.purchasePackage;
          textSecondButton = 'BUTTON.PURCHASE';
          content = (
            <div className={classes.contentWrapper}>
              <NoAvailablePackages
                data={packageConsume}
                handleSelectPackage={this.handleSelectPackage}
                selectedPackage={selectedPackage}
              />
            </div>
          );

          break;

        default:
          handleSecondButton = this.saveOrder;
          textSecondButton = 'BUTTON.SAVE';
          content = (
            <PlanSwitch planToHide="CONTROL" key="package-cosumption">
              <div className={classes.contentWrapper}>
                <PackageConsumption data={packageConsume} />
              </div>
            </PlanSwitch>
          );
      }

      if (this.features.indexOf('FORM_ACCESSINFO') > 0) {
        content = [
          <UnproductiveWarning
            key="unproductive-warning"
            onChange={this.handleUnproductiveCheckChange}
            submitted={submitted}
          />,
          content,
        ];
      }
    }

    return (
      <Grid container className={classes.container}>
        <ApiContext.Provider value={{ status: apiStatus }}>
          <ClientSteps activeStep={step} steps={this.steps} />

          <Grid item xs={12} className={classes.form}>
            {content}
          </Grid>

          <OrderFormPageFooter
            handleFirstButton={step === 0 ? this.handleCancel : this.handleBack}
            handleSecondButton={handleSecondButton}
            textFirstButton={step === 0 ? 'BUTTON.CANCEL' : 'BUTTON.BACK'}
            textSecondButton={textSecondButton}
          >
            {(errors > 0 || errorMessage) && (
              <FormError>
                {errorMessage ? (
                  <FormattedMessage
                    id={errorMessage}
                    values={{
                      a: children => (
                        <a
                          href={`/marketplace/docusign#access_token=${this.accessToken}&token_type=${this.tokenType}&expires_in=`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {children}
                        </a>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage id="orderForm.footerError" values={{ errors }} />
                )}
              </FormError>
            )}
          </OrderFormPageFooter>
        </ApiContext.Provider>
      </Grid>
    );
  }
}

ClientOrderFormPage.propTypes = {
  cancelNewOrder: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  intl: PropTypes.object.isRequired,
  newOrderType: PropTypes.string.isRequired,
  previousOrderId: PropTypes.number,
  previousOrderFile: PropTypes.object,
  previousPropertyId: PropTypes.number,
  syncTags: PropTypes.func.isRequired,
  updateOrderItem: PropTypes.func,
};

ClientOrderFormPage.defaultProps = {
  previousOrderId: null,
  previousOrderFile: null,
  previousPropertyId: null,
  updateOrderItem: null,
};

export default connect(null, { syncTags })(withRouter(injectIntl(ClientOrderFormPage)));
