import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import API from 'src/apiRequest';
import Button from '@material-ui/core/Button';
import FileDownload from '@material-ui/icons/FileDownload';
import Refresh from '@material-ui/icons/Refresh';
import classes from 'src/containers/ClientFinancialSummary/components/ExportButton/ExportButton.module.scss';
import { showToast } from 'src/store/ducks/toasts';

const ExportButton = ({ id, type, search, disabled }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const exportAccount = async exportType => {
    try {
      setLoading(true);
      const params = new URLSearchParams();
      if (search) {
        params.append('search', search);
      }
      const { data } = await API.get(`/account/export/${exportType}`, { params });
      const title = intl.formatMessage({ id: 'summaryTitle' });
      const text = intl.formatMessage({ id: 'consolidatedSummary' });

      dispatch(
        showToast({
          type: 'success',
          title,
          text,
          link: data?.file,
        })
      );
    } catch (err) {
      console.error(
        'Infelizmente um erro aconteceu ao tentar gerar o documento. A mensagem de erro recebida foi: ',
        err
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button className={classes.exportButton} disabled={loading || disabled} onClick={() => exportAccount(type)}>
        {loading ? <Refresh className={classes.iconSpin} /> : <FileDownload className={classes.icon} />}
        {intl.formatMessage({ id: id }) || ''}
      </Button>
    </>
  );
};

export default ExportButton;
