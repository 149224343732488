import React, { Component, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Grid from '@material-ui/core/Grid';

import API from 'src/apiRequest';
import PlanSwitch from 'src/components/common/PlanSwitch';
import Loading from 'src/Loading';

import classes from 'src/containers/ClientFinancialSummary/ClientFinancialSummary.module.scss';
import ExportButton from './components/ExportButton/ExportButton';
import SearchInput from './components/SearchInput/SearchInput';

const debounce = (callback, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};

class ClientFinancialSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      total: 0,
      count: 0,
      perPage: 10,
      rows: [],
      order: 'desc',
      sort: 'created_at',
      loading: false,
      search: '',
    };
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchDebounce = this.handleSearchDebounce.bind(this);
  }

  componentDidMount() {
    this.fetchData(this.state.page);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      page: 0,
      total: 0,
      count: 0,
      perPage: 10,
      rows: [],
      order: 'desc',
      sort: 'created_at',
      loading: false,
    });

    this.fetchData(this.state.page);
  }

  fetchData(page, column, order) {
    const today = new Date();
    const formattedDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const params = new URLSearchParams();
    let newOrder = this.state.order;
    let newSort = this.state.sort;
    let search = this.state.search;

    this.setState({
      loading: true,
    });
    if (column !== undefined && order !== undefined) {
      newOrder = order;
      newSort = column;
    }
    params.append('offset', page * this.state.perPage);
    params.append('limit', this.state.perPage);
    params.append('startDate', formattedDate);
    params.append('orderBy', newSort);
    params.append('orderByDir', newOrder);
    if (search) {
      params.append('search', search);
    }
    API.get('/accounts/consolidated', { params })
      .then(res => {
        const items = res.data;
        this.setState({
          ...this.state,
          page: items.page - 1,
          total: items.total,
          count: items.quantity,
          rows: items.data ? items.data : [],
          order: newOrder,
          sort: newSort,
          loading: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChangePage = (event, page) => {
    this.fetchData(page);
  };

  /*
      Function to change the order of the elements from the table based on
      one column
    */
  handleOrderChange(column) {
    if (this.state.sort === column && this.state.order !== 'desc') {
      this.fetchData(this.state.page, column, 'desc');
    } else {
      this.fetchData(this.state.page, column, 'asc');
    }
  }

  handleSearchDebounce = debounce(page => this.fetchData(page), 500);

  handleSearch(e) {
    this.setState({ ...this.state, search: e.target.value });
    this.handleSearchDebounce(this.state.page);
  }

  render() {
    return (
      <Grid className={classes.clientFinancialSummary}>
        <Grid container className={classes.filters} direction="row">
          <Grid item className={classes.gridInput} xs={12} sm={5} lg={4}>
            <SearchInput
              id="clientFinancialSummary.inputSearch"
              onChange={this.handleSearch}
              defaultValue={this.state.search}
              classe={classes.input}
            />
          </Grid>
          <ExportButton
            id="clientFinancialSummary.buttonExportAccount"
            type="account"
            search={this.state.search}
            disabled={!this.state.rows.length || this.state.loading}
          />
          <ExportButton
            id="clientFinancialSummary.buttonExportConsolidated"
            type="consolidated"
            search={this.state.search}
            disabled={!this.state.rows.length || this.state.loading}
          />
        </Grid>
        {this.state.loading ? (
          <Grid className={classes.loading}>
            <Loading clientPanel paperBackground="white" />
          </Grid>
        ) : (
          <>
            {this.state.rows.length ? (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="Financial.operation" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="Financial.entity" />
                      </TableCell>
                      <PlanSwitch planToHide="CONTROL">
                        <TableCell>
                          <TableSortLabel
                            active={this.state.sort === 'credits'}
                            direction={this.state.order}
                            order={this.state.order}
                            onClick={() => this.handleOrderChange('credits')}
                          >
                            <FormattedMessage id="Financial.credits" />
                          </TableSortLabel>
                        </TableCell>
                      </PlanSwitch>
                      <TableCell>
                        <TableSortLabel
                          active={this.state.sort === 'area'}
                          direction={this.state.order}
                          order={this.state.order}
                          onClick={() => this.handleOrderChange('area')}
                        >
                          <FormattedMessage id="Financial.area" />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={this.state.sort === 'created_at'}
                          direction={this.state.order}
                          order={this.state.order}
                          onClick={() => this.handleOrderChange('created_at')}
                        >
                          <FormattedMessage id="Financial.createDate" />
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={this.state.sort === 'updated_at'}
                          direction={this.state.order}
                          order={this.state.order}
                          onClick={() => this.handleOrderChange('updated_at')}
                        >
                          <FormattedMessage id="Financial.lastUpdateDate" />
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.rows.map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <FormattedMessage id={['Financial', row.operation].join('.')} />
                        </TableCell>
                        <TableCell>{row.entity}</TableCell>
                        <PlanSwitch planToHide="CONTROL">
                          <TableCell>
                            {(row.credits / 100).toString().replace('.', ',')} {row.credits.unit}
                          </TableCell>
                        </PlanSwitch>
                        <TableCell>{row.area || '-'}</TableCell>
                        <TableCell>{row.created_at}</TableCell>
                        <TableCell>{row.updated_at}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={this.state.total}
                  rowsPerPage={this.state.perPage}
                  page={this.state.page}
                  labelRowsPerPage={null}
                  onChangePage={this.handleChangePage}
                  rowsPerPageOptions={[this.state.perPage]}
                />
              </>
            ) : (
              <Grid className={classes.noResults}>
                <FormattedMessage id="clientFinancialSummary.noResults" />
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  }
}

export default ClientFinancialSummary;
