import React from 'react';
import { useIntl } from 'react-intl';

const SearchInput = ({ id, defaultValue, classe, onChange }) => {
  const intl = useIntl();
  return (
    <>
      <input
        className={classe}
        placeholder={intl.formatMessage({ id: id })}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default SearchInput;
