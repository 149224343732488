import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ClientAppsMenu from 'src/components/ClientAppsMenu';
import MovideskChatButton from 'src/components/MovideskChatButton';
import OctadeskChatButton from 'src/components/OctadeskChatButton';
import { useUser } from 'src/contexts/UserContext';
import classes from 'src/HeaderMenu.module.scss';
import contactImage from 'src/images/icons/header-contact.svg';
import faqImage from 'src/images/icons/header-faq.svg';
import { checkAccessTokenIsExpired } from 'src/services/isTokenExpired';
import CircleRede from 'src/svg-icons/circle-rede';

const HeaderMenu = () => {
  const [disabledByModality, setDisabledByModality] = useState(false);
  const [open, setOpen] = useState(false);
  const buttonEl = useRef(null);

  const user = useUser();

  const franchiseeContact = user?.franchiseeContact || null;

  const isClient = user?.authRole === 'ROLE_CLIENT';
  const isClientWithSupportWidgetFeature = isClient && user?.features?.includes('CLIENT_SUPPORT_WIDGET');

  useEffect(() => {
    const me = JSON.parse(window.sessionStorage.getItem('me'));
    setDisabledByModality(me.disabledByModality);
  }, []);

  const integrations = () => {
    window.location = '/marketplace';
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = event => {
    if (buttonEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = () => {
    window.sessionStorage.clear();

    checkAccessTokenIsExpired();
  };

  const handleDeleteOctadeskScript = () => {
    const octadeskElements = document.querySelectorAll('[class^="octa"]');

    if (octadeskElements.length > 0) {
      octadeskElements.forEach(octadeskElement => octadeskElement.remove());
    }
  };

  useEffect(() => {
    !isClientWithSupportWidgetFeature && handleDeleteOctadeskScript();
  }, [isClientWithSupportWidgetFeature]);

  return (
    <div className={classes.headerMenu} data-cy="header-menu">
      {franchiseeContact && (
        <a href={`https://wa.me/${franchiseeContact.value}`} target="_blank" rel="noopener noreferrer">
          <img src={contactImage} alt="Contato via WhatsApp" />
        </a>
      )}
      {isClientWithSupportWidgetFeature && <OctadeskChatButton />}
      {!isClient ? (
        <MovideskChatButton />
      ) : (
        <a href="https://gruporv.movidesk.com/kb" target="_blank" rel="noopener noreferrer">
          <img src={faqImage} alt="Faq" />
        </a>
      )}
      <button type="button" ref={buttonEl} onClick={handleToggle} data-cy="logout-menu-button">
        <div className={classes.name}>{user?.name}</div>
        <div className={classes.icon}>
          <CircleRede />
        </div>
      </button>
      <Menu
        id="long-menu"
        className={classes.longMenu}
        anchorEl={buttonEl.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          'data-cy': 'logout-menu',
          style: {
            maxHeight: 48 * 4.5,
            width: 250,
          },
        }}
      >
        {isClient && user?.features.includes('INTEGRATION') ? (
          <MenuItem onClick={integrations}>
            <FormattedMessage id="INTEGRATION" />
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleLogout} data-cy="logout-button">
          <FormattedMessage id="LOGOUT" />
        </MenuItem>
      </Menu>
      {isClient && <ClientAppsMenu />}
    </div>
  );
};

export default HeaderMenu;
